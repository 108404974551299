import { User } from 'api/users/types';
import React, { createContext, useContext } from 'react';

const ProfileContext = createContext<ProfileContextType | null>(null);

interface ProfileContextType {
  user: User;
  authUser: User | null;
}

interface ProfileProviderProps {
  user: User;
  authUser: User | null;
  children: React.ReactNode;
}

const ProfileProvider : React.FC<ProfileProviderProps> = ({ user, authUser, children }) => {
  const contextValue = { user, authUser };
  return (
    <ProfileContext.Provider value={contextValue}>
      {children}
    </ProfileContext.Provider>
  );
};
export default ProfileProvider;

export const useProfile = () => {
  const context = useContext(ProfileContext);
  if (!context) {
    throw new Error('useProfile must be used within a ProfileProvider');
  }
  return context;
};