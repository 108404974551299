import axiosInstance from '../axiosInstances';
import { TokenListResponse, Token, FetchParams } from './types';

const tokens = {
  getByIdentifier: async (collection_slug:string, identifier:string, params?: FetchParams): Promise<Token> => {
    const response = await axiosInstance.get(`/collections/${collection_slug}/tokens/${identifier}`, {
      params,
    });
    return response.data.data as Token;
  },
  fetchAll: async (collection_slug:string, params?: FetchParams): Promise<TokenListResponse> => {
    const response = await axiosInstance.get(`/collections/${collection_slug}/tokens`, {
      params,
    });
    return response.data as TokenListResponse;
  },
  fetchCursor: async (cursor:string): Promise<TokenListResponse> => {
    const response = await axiosInstance.get(`${cursor}`);
    return response.data as TokenListResponse;
  },
};

export default tokens;