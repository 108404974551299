import * as React from 'react';
import { configureStore } from '@reduxjs/toolkit';
import { render, RenderOptions } from '@testing-library/react';
import { PropsWithChildren } from 'react';
import { Provider } from 'react-redux';
import axiosInstance from '../api/axiosInstances';
import fvApi from '../api/fvApi';
import rootReducer from './rootReducer';

export type PreloadedState = Partial<RootState>;

interface ExtendedRenderOptions extends Omit<RenderOptions, 'queries'> {
    preloadedState?: PreloadedState;
    store?: ReturnType<typeof setupStore>;
}

export const setupStore = (preloadedState?: PreloadedState) =>
    configureStore({
        reducer: rootReducer,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                thunk: {
                    extraArgument: fvApi,
                },
                immutableCheck: { warnAfter: 256 },
                serializableCheck: { warnAfter: 256 },
            }),
        preloadedState,
    });

export const renderWithProviders = (
    ui: React.ReactElement,
    {
        preloadedState = {},
        store = setupStore(preloadedState),
        ...renderOptions
    }: ExtendedRenderOptions = {},
) => {
    function Wrapper({ children }: PropsWithChildren<{}>): JSX.Element {
        return <Provider store={store}>{children}</Provider>;
    }
    return { store, ...render(ui, { wrapper: Wrapper, ...renderOptions }) };
};

export const store = setupStore();

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;

export type AsyncThunkConfig = {
    state: RootState;
    dispatch: AppDispatch;
    extra: typeof fvApi;
    rejectValue: any;
    serializedErrorType?: any;
    pendingMeta?: any;
    fulfilledMeta?: any;
    rejectedMeta?: any;
};

axiosInstance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('authToken');
        const language = localStorage.getItem('acceptLanguage');

        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }

        if (language) {
            config.headers['Accept-Language'] = language;
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default store;