import axiosInstance from '../axiosInstances';
import { SliderListResponse } from './types';

const sliders = {
  fetchAll: async (showMenu?: boolean): Promise<SliderListResponse> => {
    const response = await axiosInstance.get(`/sliders`, {
      params: { showMenu },
    });
    return response.data as SliderListResponse;
  },
};

export default sliders;