import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { AsyncThunkConfig, RootState } from '../store';
import {
    AddActionCases,
    GenericSliceState,
    HandleAsyncActionError,
} from '../../utils/helpers/reduxToolkitHelper';
import { User } from 'api/users/types';
import { AuthUserParams } from 'api/auth/types';

export interface AuthState extends GenericSliceState {
    isAuthenticated: boolean;
    user: User | null;
}

export const initialState: AuthState = {
    isLoading: false,
    errorCode: null,
    errorMessage: '',

    isAuthenticated: Boolean(localStorage.getItem('user')),
    user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') as string) : null,
};

// ------------- //
// Async Actions //
// ------------- //

export const login = createAsyncThunk<User, AuthUserParams, AsyncThunkConfig>(
    'auth/login',
    async (params, thunkAPI) => {
        try {
            const result = await thunkAPI.extra.auth.login(params);
            return result;
        } catch (err: any) {
            return HandleAsyncActionError(err, thunkAPI);
        }
    },
);

// ----- //
// Slice //
// ----- //

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        // -------------- //
        // Sync Reducers //
        // -------------- //

        clearAuthSlice: (state, action: PayloadAction<void>) => Object.assign(state, initialState),

        setAuthentication: (state, action: PayloadAction<boolean>) => {
            state.isAuthenticated = action.payload;
        },
        setUser: (state, action: PayloadAction<User | null>) => {
            state.user = action.payload;
            localStorage.setItem('user', JSON.stringify(action.payload));
        },
        logout: (state) => {
            // @debug
            // console.log('auth.slice logout was called');

            state.isAuthenticated = false;
            state.user = null;
            localStorage.removeItem('user');
        },
    },
    extraReducers: builder => {
        // -------------- //
        // Async Reducers //
        // -------------- //

        AddActionCases(
            builder,
            login,
            (state, action) => {
                // @debug
                // console.log('AddActionCases LOGIN setUser', action.payload);
                
                // setAuthentication(true);
                // setUser(action.payload);
                state.isAuthenticated = true;
                state.user = action.payload;
                localStorage.setItem('user', JSON.stringify(action.payload));
            },
            (state, action) => {
                // setAuthentication(false);
                // setUser(null);
                state.isAuthenticated = false;
                state.user = null;
            },
            (state, action) => {},
        );

        
    },
});

// ------------ //
// Sync Actions //
// ------------ //
export const {
    clearAuthSlice,
    logout,
    setAuthentication,
    setUser,
} = authSlice.actions;

export const authSliceSelector = (state: RootState) => state.auth;
export default authSlice.reducer;
