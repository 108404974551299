import React, { useEffect } from 'react'
import moment from 'moment';
import Navbar from '../../components/navbar'
import Footer from '../../components/footer'

import { AppDispatch, RootState } from 'store/store';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchAll, fetchCursor } from 'store/blogs/blogs.slice';
import { Blog } from 'api/blogs/types';
import LazyImage from '../../components/lazy-image';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

export default function Blogs() {
    const dispatch = useDispatch<AppDispatch>();
    const { t } = useTranslation();
    const { blogs, isLoadingB = true } = useSelector((state: RootState) => state.blogs);
    const per_page:number = 9;
    
    useEffect(() => {
        dispatch(fetchAll({ top: per_page}));

        // Scroll to top when component mounts
        const timeoutId = setTimeout(() => {
            window.scrollTo(0, 0);
        }, 1000);
    
        return () => clearTimeout(timeoutId);
    }, [dispatch, per_page]);

    const handleLoadMore = () => {
        if (blogs?.links?.next) {
            dispatch(fetchCursor({cursor: blogs.links.next, params: {top: per_page}}));
        }
    };

    return (
    <>
    <Helmet>
        <title>{t('footer.head.2.menu.2')} - {process.env.REACT_APP_NAME}</title>
        <script type="application/ld+json">
            {JSON.stringify({
                '@context': 'https://schema.org',
                '@type': 'WebSite',
                name: process.env.REACT_APP_NAME,
                url: window.location.origin
            })}
        </script>
        <script type="application/ld+json">
            {JSON.stringify({
                '@context': 'https://schema.org',
                '@type': 'Organization',
                name: process.env.REACT_APP_NAME,
                url: window.location.origin,
                logo: window.location.origin + '/assets/images/logo-light.webp',
            })}
        </script>
    </Helmet>
    <Navbar navlight={true} gradient={true}/>
    <section className="bg-half-170 d-table w-100 position-relative">
        <div className="bg-overlay bg-gradient-overlay-2"></div>
        <video autoPlay loop muted playsInline className="w-100 h-100 position-absolute top-0 start-0" style={{ objectFit: 'cover', zIndex: -1 }}>
            <source src="https://demowines.follovers.art/assets/videos/category-blog.mp4" type="video/mp4" />
            Your browser does not support the video tag.
        </video>
        <div className="container">
            <div className="row mt-5 justify-content-center">
                <div className="col-12">
                    <div className="title-heading text-center">
                        <h5 className="heading fw-semibold sub-heading text-white title-dark">{t('blog.labels.title')}</h5>
                        <p className="text-white-50 para-desc mx-auto mb-0">{t('blog.labels.subtitle')}</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <div className="position-relative">            
        <div className="shape overflow-hidden text-white">
            <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
            </svg>
        </div>
    </div>

    <section className="section">
        <div className="container">
            <div className="row g-4">

                {!isLoadingB && blogs?.data && blogs?.data.length === 0 && (
                    <>
                    <div className="content"><p className="text-muted">{t('blog.items_not_found')}</p></div>
                    </>
                )}

                {blogs && blogs.data.map((item:Blog)=>{
                    return (
                    <div className="col-lg-4 col-md-6" key={item.id}>
                        <div className="card blog blog-primary shadow rounded-md overflow-hidden">
                            <div className="position-relative">
                                <Link to={`/blog/${item.slug}`} className=""><LazyImage src={item.image_permalink} className="img-fluid rounded-md" alt={item.slug} height='223px' /></Link>
                            </div>
                            <div className="card-body position-relative p-4">
                                <ul className="list-unstyled mt-2">
                                    <li className="list-inline-item text-muted small me-3"><i className="uil uil-calendar-alt text-dark h6 me-1"></i>{moment(item.blog_date).format('L')}</li>
                                </ul>
                                <Link to={`/blog/${item.slug}`} className="text-dark title h5 mt-3">{item.title}</Link>
                                <p className="text-muted mt-4 mb-0">{item.short_description}</p>
                                
                                <div className="mt-3 d-flex justify-content-between align-items-center">
                                    <Link to={`/blog/${item.slug}`} className="btn btn-link text-muted">{t('common.buttons.read_more.label')} <i className="mdi mdi-arrow-right"></i></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    )}
                )}

                {isLoadingB && Array.from({ length: 3 }).map((_, index) => (
                    <div className="col-lg-4 col-md-6" key={index}>
                        <div className="card blog blog-primary shadow rounded-md overflow-hidden">
                            <div className="position-relative">
                                <div className="img-fluid rounded-md bg-secondary" style={{ height: '223px' }}></div>
                            </div>
                            <div className="card-body position-relative p-4">
                                <ul className="list-unstyled mt-2">
                                    <li className="list-inline-item text-muted small me-3" style={{ width: '100px' }}>
                                        <span className="placeholder col-6"></span>
                                    </li>
                                </ul>
                                <h5 className="text-dark title mt-3">
                                <span className="placeholder col-8"></span>
                                </h5>
                                <p className="text-muted mt-4 mb-0">
                                <span className="placeholder col-12"></span>
                                <span className="placeholder col-10"></span>
                                </p>
                                <div className="mt-3 d-flex justify-content-between align-items-center">
                                <span className="btn btn-link text-muted placeholder col-4"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}

                {blogs?.links && blogs.links.next && (
                    <div className="row justify-content-center mt-4">
                        <div className="col">
                            <div className="text-center">
                                <button onClick={handleLoadMore} className="btn btn-dark rounded-md">
                                    {isLoadingB ?
                                        <>
                                        <i className="uil uil-process mdi-spin me-1"></i> {t('common.buttons.load_more.loading')}
                                        </>
                                        :
                                        <>
                                        {t('common.buttons.load_more.label')}
                                        </>
                                    }
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    </section>
    <Footer/>
    </>
  )
}
