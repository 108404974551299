import { arbitrum, mainnet, sepolia } from 'wagmi/chains';
import type { CreateConnectorFn } from '@wagmi/core'
import { walletConnect, coinbaseWallet, injected } from "wagmi/connectors";
import { authConnector } from '@web3modal/wagmi';
import { http, createConfig } from "wagmi";


const projectId = process.env.REACT_APP_WALLETCONNECT_PROJECT_ID || '';
if (!projectId) throw new Error("Project ID is undefined");


const metadata = {
  name: 'Follovers',
  description: 'En Follovers, ayudamos a Trendsetters a fortalecer el vínculo con su audiencia mediante coleccionables digitales oficiales, utilizando tecnología blockchain.',
  url: 'https://web3modal.com',
  icons: ['https://app.follovers.art/assets/images/logo.webp'],
};

const chains = [mainnet, arbitrum, sepolia] as const;

// create the connectors
const connectors: CreateConnectorFn[] = []
connectors.push(walletConnect({ projectId, metadata, showQrModal: false }));
connectors.push(injected({ shimDisconnect: true }));
connectors.push(coinbaseWallet({
  appName: metadata.name,
  appLogoUrl: metadata.icons[0],
}));

connectors.push(authConnector({ 
  options: { projectId },
  socials: ['google', 'x', 'github', 'discord', 'apple'], // this will create a non-custodial wallet (please check https://secure.walletconnect.com/dashboard for more info)
  showWallets: true,
  email: true,
  walletFeatures: false,
}));

const Web3ModalConfig = createConfig({
  chains, // Use the defined chains here
  transports: {
    [mainnet.id]: http(),
    [arbitrum.id]: http(),
    11155111: http(),
  },
  connectors: connectors,
});

/*
const Web3ModalConfig = defaultWagmiConfig({
  chains,
  projectId,
  metadata,
  auth: {
    email: true, // default to true
    socials: ['google', 'x', 'discord', 'apple', 'facebook'],
    showWallets: true, // default to true
    walletFeatures: true // default to true
  }
});
*/
export default Web3ModalConfig;
