import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AsyncThunkConfig, RootState } from '../store';

import {
    AddActionCases,
    GenericSliceState,
} from '../../utils/helpers/reduxToolkitHelper';
import { MessageResponse } from 'api/common.types';

export interface suscribersState extends GenericSliceState {
    isLoading: boolean;
    errorCode: string | null;
    errorMessage: string;
    messageResponse: MessageResponse | null;
}

export const initialState: suscribersState = {
    isLoading: false,
    errorCode: null,
    errorMessage: '',
    messageResponse: null,
};

// ------------- //
// Async Actions //
// ------------- //

export const createSuscriber = createAsyncThunk<MessageResponse, { email: string }, AsyncThunkConfig>(
    'suscribers/createSuscriber',
    async ({ email }, thunkAPI) => {
        try {
            const result = await thunkAPI.extra.suscribers.createSuscriber(email);
            return result;
        } catch (err: any) {
            return thunkAPI.rejectWithValue(err.response.data);
        }
    },
);


// ----- //
// Slice //
// ----- //

export const suscribersSlice = createSlice({
    name: 'suscribers',
    initialState,
    reducers: {
        // -------------- //
        // Sync Reducers //
        // -------------- //
    },
    extraReducers: (builder) => {
        // -------------- //
        // Async Reducers //
        // -------------- //

        AddActionCases(
            builder,
            createSuscriber,
            (state, action) => {
                state.messageResponse = action.payload;
            },
            (state, action) => {
                state.messageResponse = null;
            },
            (state, action) => {
                state.messageResponse = action.payload;
            },
        );

    },
});

// ------------ //
// Sync Actions //
// ------------ //
export const suscribersSelector = (state: RootState) => state.suscribers;
export default suscribersSlice.reducer;
