import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom'

import { fetchHome } from '../store/blogs/blogs.slice';
import { AppDispatch, RootState } from '../store/store';
import { Blog } from '../api/blogs/types';
import LazyImage from './lazy-image';

import { useTranslation } from 'react-i18next';

export default function BlogHome() {
    const dispatch = useDispatch<AppDispatch>();
    const { t } = useTranslation();
    const { blogsHome, isLoadingBH = true } = useSelector((state: RootState) => state.blogs);
    
    useEffect(() => {
        dispatch(fetchHome({top: 2}));
    }, [dispatch]);

    return blogsHome && (
    <>
    <div className="container-fluid mt-100 mt-60">
        <div className="row px-0">
            <div className="bg-half-100 bg-gradient-primary">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col">
                            <div className="section-title text-center mb-4 pb-2">
                                <h4 className="title text-white title-dark mb-4" dangerouslySetInnerHTML={{ __html: t('blog.home.labels.title') }} />
                                <p className="text-white-50 para-desc mb-0 mx-auto">{t('blog.home.labels.subtitle')}</p>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        {blogsHome?.data.map((item:Blog,index:number)=>{
                        return(
                        <div className="col-md-6 mt-4 pt-2" key={`${'bh-' + item.id}`}>
                            <div className="card p-4 rounded-md shadow bg-white">
                                <div className="mb-4">
                                    <Link to={`/blog/${item.slug}`}><LazyImage src={item.image_permalink} className="img-fluid rounded-md" alt={item.slug} height='311px' style={{maxHeight:'311px', margin:'0 auto'}}/></Link>
                                </div>
                                <Link to={`/blog/${item.slug}`} className="text-dark title h4 mb-4">{item.title}</Link>
                                <p className="text-muted mb-0">{item.short_description}</p>

                                <div className="mt-3">
                                    <Link to={`/blog/${item.slug}`} className="btn btn-link primary text-dark">{t('common.buttons.read_more.label')} <i className="uil uil-arrow-right"></i></Link>
                                </div>
                            </div>
                        </div>
                        )})}
                        
                        {isLoadingBH && Array.from({ length: 2 }).map((_, index) => (
                            <div className="col-md-6 mt-4 pt-2" key={`${'bh-' + index}`}>
                                <div className="card p-4 rounded-md shadow bg-white">
                                    <h4 className="mb-4 placeholder-glow">
                                        <span className="placeholder col-6"></span>
                                    </h4>
                                    <p className="text-muted mb-0 placeholder-glow">
                                        <span className="placeholder col-7"></span>
                                        <span className="placeholder col-4"></span>
                                        <span className="placeholder col-4"></span>
                                        <span className="placeholder col-6"></span>
                                        <span className="placeholder col-8"></span>
                                    </p>

                                    <div className="mt-3 placeholder-glow">
                                        <span className="placeholder col-6"></span>
                                    </div>
                                    <div className="py-4"></div>
                                    <div className="position-absolute bottom-0 end-0 placeholder-glow">
                                        <div className="avatar avatar-medium bg-secondary opacity-05" style={{ height: '50px', width: '50px' }}></div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}
