import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
// import LanguageDetector from 'i18next-browser-languagedetector';
import HttpBackend from 'i18next-http-backend';
import moment from 'moment';
import 'moment/locale/es';

const language = localStorage.getItem('language') || (navigator.language.split('-')[0]) || 'en';


i18n
  .use(HttpBackend)
  // .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    //backend: {
    //  loadPath: '/locales/{{lng}}/{{ns}}.json',
    //},
    lng: language,
    fallbackLng: language,
    interpolation: {
      escapeValue: false,
    },
    // react: {
    //   useSuspense: false,
    // },
  });

// Set moment locale with i18next
moment.locale(i18n.language);

// onChange language set new moment locale
i18n.on('languageChanged', (lng) => {
  moment.locale(lng);
  localStorage.setItem('language', lng);
});

export default i18n;