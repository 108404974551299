import React, { useEffect, useState } from 'react';
import { Collection } from '../../api/collections/types';
import CollectionQuantitySelector from './collection-quantity-selector';
import { getCurrentStage } from 'utils/helpers/collectionHelper';
import { CrossmintPayButton } from "@crossmint/client-sdk-react-ui";
import { useAppContext } from 'context/AppContextProvider';
import { useTranslation } from 'react-i18next';

interface CollectionMintProps {
    collection: Collection;
}

const CollectionMint : React.FC<CollectionMintProps> = ({ collection }) => {
    const { t } = useTranslation();
    const { authUser } = useAppContext();
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const stage = getCurrentStage(collection?.stages || [], userTimeZone);
    const collectionContract = collection.contracts ? collection.contracts[0] : null;

    let [quantity, setQuantity] = useState<number>(0);
    let [totalPrice, setTotalPrice] = useState<number>(0);

    useEffect(() => {
        if (stage) {
            setQuantity(1);
            setTotalPrice(parseFloat(stage.sale_price));
        }
    }, [stage]);
    
    if (!stage || !collectionContract) return null;
    
    const onChange = (value: number) => {
        setQuantity(value);
        const calculatedPrice = parseFloat(stage.sale_price) * value;
        setTotalPrice(parseFloat(calculatedPrice.toFixed(5)));
    };

    const projectId = collectionContract.cm_project_id || process.env.REACT_APP_CROSSMINT_PROJECT_ID || '';
    const collectionId = collectionContract.cm_collection_id || '';
    const environment = collectionContract.chain
        ? collectionContract.chain.network === 'mainnet'
        ? 'production'
        : 'staging'
        : process.env.REACT_APP_CROSSMINT_ENVIRONMENT || 'staging';

    const mintConfig = {
        totalPrice: totalPrice.toString(),
        _qty: quantity.toString(),
        type: collectionContract.token_standard?.name.toLowerCase(),
        ...(collectionContract.token_number && { _tokenId : collectionContract.token_number })
    };

    const whPassThroughArgs = {
        contract_id: collectionContract.id,
        qty: quantity,
        ...(authUser && { user_id : authUser?.id })
    };

    return (
        <div className="row">
            <div className="col-auto text-center mt-4 pt-2">
                <div className="d-flex justify-content-center align-items-center">
                    <CollectionQuantitySelector min={1} max={collectionContract.total_available} onChange={onChange} />
                    
                    {/* checkoutProps={{"paymentMethods":["ETH"]}} */}
                        
                    <CrossmintPayButton
                        collectionId={collectionId}
                        projectId={projectId}
                        environment={environment}
                        mintConfig={mintConfig}
                        disabled={quantity === 0 || collectionContract.total_available === 0}
                        mintTo={authUser && authUser.wallets ? authUser.wallets[0].wallet_address : ''}
                        whPassThroughArgs={whPassThroughArgs}
                        className="xmint-btn btn btn-primary btn-primary-lg ms-4"
                        getButtonText={(connecting, paymentMethod) =>
                            connecting ? t('common.buttons.buy.loading') : t('common.buttons.buy.label') // `Pay with ${paymentMethod}`
                        }
                    />
                    
                </div>
            </div>
        </div>
    );
};

export default CollectionMint;