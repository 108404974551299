import React from 'react'
import Navbar from '../../components/navbar'
import BestInfluencers from '../../components/creater/best-influencers'
import Footer from '../../components/footer'
import SliderHome from '../../components/slider-home';
import FeaturedCollection from '../../components/collection/featured-collection'
import MintingNowCollection from '../../components/collection/minting-now-collection'
import TrendingCollection from 'components/collection/trending-collection'
import ComingSoonCollection from 'components/collection/coming-soon-collection'
import BlogHome from 'components/blog-home';
import { Helmet } from 'react-helmet-async';

export default function Index() {
    
  return (
    <>
    <Helmet>
      <title>{process.env.REACT_APP_NAME}</title>
      <script type="application/ld+json">
          {JSON.stringify({
              '@context': 'https://schema.org',
              '@type': 'WebSite',
              name: process.env.REACT_APP_NAME,
              url: window.location.origin
          })}
      </script>
      <script type="application/ld+json">
          {JSON.stringify({
              '@context': 'https://schema.org',
              '@type': 'Organization',
              name: process.env.REACT_APP_NAME,
              url: window.location.origin,
              logo: window.location.origin + '/assets/images/logo-light.webp',
          })}
      </script>
    </Helmet>
    <Navbar navlight={true} gradient={true}/>

    <section>
        <SliderHome/> 
    </section>
   
    <section className="section">
        
        <BestInfluencers />
        
        <FeaturedCollection/> 
        
        <MintingNowCollection/>
        
        <TrendingCollection/>

        <BlogHome/>

        <ComingSoonCollection/>
    </section>
    <Footer/>

    </>
  )
}
