import React, { useEffect, useState  } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom'

import { fetchCategoryCollections } from '../../store/collections/collections.slice';
import { AppDispatch, RootState } from '../../store/store';
import { Collection, FetchParams } from '../../api/collections/types';
import { Category } from '../../api/categories/types';
import LazyImage from '../lazy-image';
import { CollectionListResponse } from 'api/stats/types';
import { getNextStage } from 'utils/helpers/collectionHelper';
import { formatSalePrice } from 'utils/helpers/formatHelper';
import { useTranslation } from 'react-i18next';
import CategoryBadge from './category-badge';

interface Props {
    params?: FetchParams;
}

const CategoryCollection: React.FC<Props> = ({params = {}}) =>{
    const dispatch = useDispatch<AppDispatch>();
    const { t } = useTranslation();
    const { isLoadingCategory = true } = useSelector((state: RootState) => state.collections);
    const [categoryCollections = null, setCategoryCollections] = useState<CollectionListResponse | null>(null);
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    
    useEffect(() => {
        const fetchData = async () => {
            setCategoryCollections(null);
            const result = await dispatch(fetchCategoryCollections(params));
            setCategoryCollections(result.payload);
        };
        fetchData();

        // dispatch(clearCategoryCollections());
        // dispatch(fetchCategoryCollections(params));
    }, [dispatch, params]);

    return (
    <div className="container mt-100 mt-60">
        <div className="row justify-content-center">
            <div className="col">
                <div className="section-title text-center mb-4 pb-2">
                    <h4 className="title mb-4">{t('collection.category.labels.title')}</h4>
                    <p className="text-muted para-desc mb-0 mx-auto">{t('collection.category.labels.subtitle')}</p>
                </div>
            </div>
        </div>
        <div className="row row-cols-xl-4 row-cols-lg-3 row-cols-sm-2 row-cols-1 g-4" id="grid">
        {categoryCollections && categoryCollections.data.map((item:Collection)=>{
            const stage = getNextStage(item?.stages || [], userTimeZone);
            const contract = item?.contracts && item.contracts.length > 0 ? item.contracts[0] : null;
            return(
                <div className="col picture-item" key={`${'mn-' + item.id}`}>
                    <div className="card nft-items nft-primary rounded-md shadow overflow-hidden mb-1 p-3">
                        <div className="d-flex align-items-center">
                            <div className="img-group">
                                <Link to={`/profile/${item.owner.username}`} className="user-avatar">
                                    <LazyImage src={item.owner.profile_photo_url} alt="user" className="avatar avatar-sm-sm img-thumbnail border-0 shadow-sm rounded-circle" height='32px'/>
                                </Link>
                            </div>
                            <div className="ms-3">
                                <h6 className="mb-0"><Link to={`/profile/${item.owner.username}`} className="text-dark name">@{item.owner.username}</Link></h6>
                            </div>
                        </div>

                        <div className="nft-image rounded-md mt-3 position-relative overflow-hidden" style={{maxHeight:'337px'}}>
                            <Link to={`/collection/${item.slug}`}><LazyImage src={item.logo_permalink} className="img-fluid" alt={item.slug} height='230px' style={{objectFit:'cover'}}/></Link>
                            
                            {/* {item.categories?.map((category: Category) => category.name).join(', ') || 'No categories available'} */}
                            {item.categories && item.categories.length > 0 && (
                                <div className="position-absolute top-0 start-0 m-2">
                                    {item.categories.map((category: Category) => (
                                    <CategoryBadge key={category.id} category={category} />
                                    ))}
                                </div>
                            )}
                            {/* <div className="position-absolute top-0 end-0 m-2">
                                <Link to={`/collection/${item.slug}`} className="btn btn-pills btn-icon"><i className="uil uil-shopping-cart-alt text-white"></i></Link>
                            </div> */}
                        </div>

                        <div className="card-body content position-relative p-0 mt-3 d-flex flex-column" style={{minHeight:'85px'}}>
                            <Link to={`/collection/${item.slug}`} className="title text-dark h6">{item.name}</Link>
                            <div className="mt-auto">
                                {contract?.is_soldout ? (
                                    <div className="d-flex justify-content-between mt-2">
                                        <small className="rate fw-bold">{t('collection.contracts.labels.soldout')}</small>
                                    </div>
                                ) : stage && (
                                <div className="d-flex justify-content-between mt-2">
                                    <small className="rate fw-bold">{formatSalePrice(stage.sale_price)}</small>
                                    {contract && (
                                        <small className="text-dark fw-bold">{t('collection.contracts.labels.total_minted_out_of_total_supply').replace(':total_minted', contract.total_minted.toString()).replace(':total_supply', contract.total_supply.toString())}</small>
                                    )}
                                </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )
        })}

        {isLoadingCategory && Array.from({ length: 4 }).map((_, index) => (
            <div className="col picture-item" key={`mn-${index}`}>
                <div className="card nft-items nft-primary rounded-md shadow overflow-hidden mb-1 p-3">
                    <div className="d-flex justify-content-between">
                        <div className="img-group placeholder-glow">
                            <div className="placeholder rounded-circle avatar avatar-sm-sm img-thumbnail border-0 shadow-sm"></div>
                        </div>
                    </div>

                    <div className="nft-image rounded-md mt-3 position-relative overflow-hidden placeholder-glow">
                        <div className="placeholder w-100" style={{ height: '200px' }}></div>
                    </div>

                    <div className="card-body content position-relative p-0 mt-3 placeholder-glow">
                        <div className="placeholder w-75 mb-2"></div>
                        <div className="d-flex justify-content-between mt-2">
                            <div className="placeholder w-25"></div>
                            <div className="placeholder w-25"></div>
                        </div>
                    </div>
                </div>
            </div>
        ))}
        </div>
    </div>
  )
}

export default CategoryCollection;