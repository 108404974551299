import axiosInstance from '../axiosInstances';
import { InfluencerListResponse, FetchParams } from './types';

const influencers = {
  fetchAll: async (params?: FetchParams): Promise<InfluencerListResponse> => {
    const response = await axiosInstance.get(`/influencers`, {
      params,
    });
    return response.data as InfluencerListResponse;
  },
};

export default influencers;