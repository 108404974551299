import axiosInstance from '../axiosInstances';
import { Blog, BlogListResponse, FetchParams } from './types';

const blogs = {
  fetchAll: async (params?: FetchParams): Promise<BlogListResponse> => {
    const response = await axiosInstance.get(`/blogs`, {
      params,
    });
    return response.data as BlogListResponse;
  },
  getBySlug: async (slug:string): Promise<Blog> => {
    const response = await axiosInstance.get(`/blogs/${slug}`);
    return response.data.data as Blog;
  },
  fetchCursor: async (cursor:string, params?: FetchParams): Promise<BlogListResponse> => {
    const response = await axiosInstance.get(`${cursor}`, {
      params,
    });
    return response.data as BlogListResponse;
  },
};

export default blogs;