import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSuscriber } from '../../store/suscribers/suscribers.slice';
import { AppDispatch, RootState } from 'store/store';
import { enqueueSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

const NewsletterForm: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { t } = useTranslation();
    const { isLoading } = useSelector((state: RootState) => state.suscribers);

    const [formData, setFormData] = useState({
        email: '',
    });
    
    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            const result = await dispatch(createSuscriber({ email: formData.email }));

            if(result.type === "suscribers/createSuscriber/fulfilled"){
              enqueueSnackbar(t('newsletter.messages.created_success'));
              setFormData({email: ''});
            }else{
              enqueueSnackbar(result.payload?.message, { variant: 'error' });
              // enqueueSnackbar(t('newsletter.errors.created_failed'), { variant: 'error' });
            }
        } catch (error) {
            // @debug
            console.error('Failed to suscribe', error); 
        }
    };

  return (
    <>
    <h5 className="footer-head">{t('footer.head.3.title')}</h5>
    <p className="mt-4">{t('footer.head.3.subtitle')}</p>
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-lg-12">
          <div className="foot-subscribe mb-3">
            <label className="form-label">{t('footer.head.3.input.label')} <span className="text-danger">*</span></label>
            <div className="form-icon position-relative">
              <i className="uil uil-envelope icons fs-5 mb-2"></i>
              <input
                name="email"
                type="email"
                className="form-control ps-5 rounded"
                placeholder={t('footer.head.3.input.placeholder')}
                value={formData.email}
                onChange={handleChange}
                maxLength={255}
                required
                autoComplete='off'
              />
            </div>
          </div>
        </div>
        <div className="col-lg-12">
          <div className="text-left">
            <button type="submit" className="btn btn-primary" disabled={isLoading}>
              {isLoading ? (
                  <>
                  {t('common.buttons.newsletter.suscribe.loading')}
                  </>
              ):(
                  <>
                  {t('common.buttons.newsletter.suscribe.label')}
                  </>
              )}
            </button>
          </div>
        </div>
      </div>
    </form>
    </>
  );
};

export default NewsletterForm;