import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { AsyncThunkConfig, RootState } from '../store';
import { FetchParams, InfluencerListResponse } from '../../api/influencers/types';

import {
    AddActionCases,
    GenericSliceState,
    HandleAsyncActionError,
} from '../../utils/helpers/reduxToolkitHelper';

export interface InfluencersState extends GenericSliceState {
    influencersList: InfluencerListResponse | null;
}

export const initialState: InfluencersState = {
    isLoading: false,
    errorCode: null,
    errorMessage: '',

    influencersList: null,
};

// ------------- //
// Async Actions //
// ------------- //

export const fetchInfluencers = createAsyncThunk<InfluencerListResponse, FetchParams, AsyncThunkConfig>(
    'influencers/fetchAll',
    async (params: FetchParams, thunkAPI) => {
        try {
            const result = await thunkAPI.extra.influencers.fetchAll(params);
            return result;
        } catch (err: any) {
            return HandleAsyncActionError(err, thunkAPI);
        }
    },
);


// ----- //
// Slice //
// ----- //

export const influencersSlice = createSlice({
    name: 'influencers',
    initialState,
    reducers: {
        // -------------- //
        // Sync Reducers //
        // -------------- //

        clearInfluencers: (state, action: PayloadAction<void>) =>
            Object.assign(state, initialState),
    },
    extraReducers: builder => {
        // -------------- //
        // Async Reducers //
        // -------------- //

        
        AddActionCases(
            builder,
            fetchInfluencers,
            (state, action) => {
                state.influencersList = action.payload;
            },
            (state, action) => {
                state.influencersList = null;
            },
            (state, action) => {},
        );
    },
});

// ------------ //
// Sync Actions //
// ------------ //
export const { clearInfluencers } = influencersSlice.actions;

export const influencerSelector = (state: RootState) => state.influencers;
export default influencersSlice.reducer;
