// cacheHelper.ts
interface CacheConfig {
  prefix: string;
  duration: number; // ms
}

export const APP_VERSION = process.env.REACT_APP_VERSION || 'default_version';

export function clearCache(config: CacheConfig) {
  const { prefix, duration } = config;
  const lastClearKey = `${prefix}_lastClear`;
  const lastClear = localStorage.getItem(lastClearKey);
  const now = new Date().getTime();

  if (!lastClear || now - parseInt(lastClear, 10) > duration) {
    const keys = Object.keys(localStorage);
    keys.forEach(key => {
      if (key.startsWith(prefix) && !key.endsWith(APP_VERSION)) {
        localStorage.removeItem(key);
      }
    });
    localStorage.setItem(lastClearKey, now.toString());
  }
}

export function setCacheItem(key: string, data: any) {
  const cacheKey = `${key}_${APP_VERSION}`;
  localStorage.setItem(cacheKey, JSON.stringify(data));
}

export function getCacheItem(key: string) {
  const cacheKey = `${key}_${APP_VERSION}`;
  const cachedData = localStorage.getItem(cacheKey);
  return cachedData ? JSON.parse(cachedData) : null;
}