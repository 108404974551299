import LazyImage from 'components/lazy-image';
import React from 'react';
import { Spinner } from 'react-bootstrap';
import { Tooltip } from 'react-tooltip';

interface AccountUploadFormProps {
  imageSrc: string;
  altText: string;
  inputId: string;
  labelFor: string;
  tooltipId: string;
  tooltipContent: string;
  containerClass: string;
  imgClass: string;
  labelClass: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isLoading: boolean;
}

const AccountUploadForm: React.FC<AccountUploadFormProps> = ({
  imageSrc,
  altText,
  inputId,
  labelFor,
  tooltipId,
  tooltipContent,
  containerClass,
  imgClass,
  labelClass,
  onChange,
  isLoading
}) => {
  return (
    <div className={containerClass}>
      <input
          id={inputId}
          type="file"
          className="d-none"
          onChange={onChange}
          accept="image/png, image/gif, image/bmp, image/svg+xml, image/jpeg, image/webp"
        />
      <div className="position-relative d-inline-block">
        <LazyImage src={imageSrc} className={imgClass} alt={altText} height='201px' style={{maxHeight:'201px'}} />
        <label
          data-tooltip-id={tooltipId}
          data-tooltip-content={tooltipContent}
          className={labelClass}
          htmlFor={labelFor}
        >
          <span className="btn btn-icon btn-sm btn-pills btn-primary">
            {isLoading ? (
              <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="fs-6" />
            ) : (
              <i className="uil uil-camera fs-6"></i>
            )}
          </span>
        </label>
        <Tooltip id={tooltipId} />
      </div>
    </div>
  );
};

export default AccountUploadForm;