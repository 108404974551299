import Navbar from 'components/navbar';
import React from 'react';
import BestInfluencers from 'components/creater/best-influencers';
import { DisplayThemeProps } from 'interfaces/category/DisplayThemeProps';
import CategoryCollection from 'components/collection/category-collection';
import Footer from 'components/footer';
import { Helmet } from 'react-helmet-async';

const DisplayTheme3: React.FC<DisplayThemeProps> = (props) => {
    return (
        <>
        <Helmet>
            <title>{props.category.name} - {process.env.REACT_APP_NAME}</title>
            <script type="application/ld+json">
                {JSON.stringify({
                    '@context': 'https://schema.org',
                    '@type': 'WebSite',
                    name: process.env.REACT_APP_NAME,
                    url: window.location.origin
                })}
            </script>
            <script type="application/ld+json">
                {JSON.stringify({
                    '@context': 'https://schema.org',
                    '@type': 'Organization',
                    name: process.env.REACT_APP_NAME,
                    url: window.location.origin,
                    logo: window.location.origin + '/assets/images/logo-light.webp',
                })}
            </script>
        </Helmet>
        <Navbar navlight={true} gradient={true}/>
        <section className="bg-half-170 d-table w-100 position-relative">
            <video autoPlay loop muted playsInline className="w-100 h-100 position-absolute top-0 start-0" style={{ objectFit: 'cover', zIndex: -1 }}>
                <source src="https://demowines.follovers.art/assets/videos/category-vinedo.mp4" type="video/mp4" />
                Your browser does not support the video tag.
            </video>
        </section>
        <div className="position-relative">            
            <div className="shape overflow-hidden text-shape">
                <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                </svg>
            </div>
        </div>
        <section className="section">
            <BestInfluencers params={{
                category_slug: props.category.slug
            }} />
            <CategoryCollection params={{
                category_slug: props.category.slug,
                collection_fields: 'owner,categories,stages,contracts'
            }} />
        </section>
        <Footer/>
        </>
    );
};

export default DisplayTheme3;