import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { AsyncThunkConfig, RootState } from '../store';
import { Blog, BlogListResponse, FetchParams } from '../../api/blogs/types';

import {
    AddActionCases,
    GenericSliceState,
    HandleAsyncActionError,
} from '../../utils/helpers/reduxToolkitHelper';

export interface CollectionsState extends GenericSliceState {
    blogsHome: BlogListResponse | null;
    blogs: BlogListResponse | null;
    blog: Blog | null;
    
    isLoadingBH: boolean;
    errorCodeBH: string | null;
    errorMessageBH: string;

    isLoadingB: boolean;
    errorCodeB: string | null;
    errorMessageB: string;
    
    isLoading: boolean;
    errorCode: string | null;
    errorMessage: string;
}

export const initialState: CollectionsState = {
    blogsHome: null,
    blogs: null,
    blog: null,
    
    isLoadingBH: false,
    errorCodeBH: null,
    errorMessageBH: '',
    
    isLoadingB: false,
    errorCodeB: null,
    errorMessageB: '',

    isLoading: false,
    errorCode: null,
    errorMessage: '',
};

// ------------- //
// Async Actions //
// ------------- //

export const getBySlug = createAsyncThunk<Blog, { slug: string }, AsyncThunkConfig>(
    'blogs/getBySlug',
    async ({ slug }, thunkAPI) => {
        try {
            const result = await thunkAPI.extra.blogs.getBySlug(slug);
            return result;
        } catch (err: any) {
            return HandleAsyncActionError(err, thunkAPI);
        }
    },
);

export const fetchHome = createAsyncThunk<BlogListResponse, FetchParams, AsyncThunkConfig>(
    'blogs/fetchHome',
    async (params: FetchParams, thunkAPI) => {
        try {
            const result = await thunkAPI.extra.blogs.fetchAll(params);
            return result;
        } catch (err: any) {
            return HandleAsyncActionError(err, thunkAPI);
        }
    },
);

export const fetchAll = createAsyncThunk<BlogListResponse, FetchParams, AsyncThunkConfig>(
    'blogs/fetchAll',
    async (params: FetchParams, thunkAPI) => {
        try {
            const result = await thunkAPI.extra.blogs.fetchAll(params);
            return result;
        } catch (err: any) {
            return HandleAsyncActionError(err, thunkAPI);
        }
    },
);

export const fetchCursor = createAsyncThunk<BlogListResponse, { cursor: string, params?: FetchParams }, AsyncThunkConfig>(
    'blogs/fetchCursor',
    async ({ cursor, params }, thunkAPI) => {
        try {
            const result = await thunkAPI.extra.blogs.fetchCursor(cursor, params);
            return result;
        } catch (err: any) {
            return HandleAsyncActionError(err, thunkAPI);
        }
    },
);


// ----- //
// Slice //
// ----- //

export const blogsSlice = createSlice({
    name: 'blogs',
    initialState,
    reducers: {
        // -------------- //
        // Sync Reducers //
        // -------------- //

        clearBlogs: (state, action: PayloadAction<void>) =>
            Object.assign(state, initialState),

        clearBlogsHome: (state) => {
            state.blogsHome = null;
        },
        
    },
    extraReducers: (builder) => {
        // -------------- //
        // Async Reducers //
        // -------------- //

        AddActionCases(
            builder,
            getBySlug,
            (state, action) => {
                state.blog = action.payload;
            },
            (state, action) => {
                state.blog = null;
            },
            (state, action) => {},
        );

        AddActionCases(
            builder,
            fetchHome,
            (state, action) => {
                state.blogsHome = action.payload;
            },
            (state, action) => {
                state.blogsHome = null;
            },
            (state, action) => {},
            'BH'
        );

        AddActionCases(
            builder,
            fetchAll,
            (state, action) => {
                state.blogs = action.payload;
            },
            (state, action) => {
                state.blogs = null;
            },
            (state, action) => {},
            'B'
        );

        AddActionCases(
            builder,
            fetchCursor,
            (state, action) => {
                if (state.blogs) {
                    state.blogs.data = [...state.blogs.data, ...action.payload.data];
                    state.blogs.links = action.payload.links;
                    state.blogs.meta = action.payload.meta;
                } else {
                    state.blogs = action.payload;
                }
            },
            (state, action) => {},
            (state, action) => {},
            'B'
        );
        
    },
});

// ------------ //
// Sync Actions //
// ------------ //
export const { clearBlogs, clearBlogsHome } = blogsSlice.actions;

export const blogsSelector = (state: RootState) => state.blogs;
export default blogsSlice.reducer;
