import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { AsyncThunkConfig, RootState } from '../store';
import { SliderListResponse } from '../../api/sliders/types';

import {
    AddActionCases,
    GenericSliceState,
    HandleAsyncActionError,
} from '../../utils/helpers/reduxToolkitHelper';

export interface SlidersState extends GenericSliceState {
    sliderList: SliderListResponse | null;
}

export const initialState: SlidersState = {
    isLoading: false,
    errorCode: null,
    errorMessage: '',

    sliderList: null,
};

// ------------- //
// Async Actions //
// ------------- //

export const fetchSliders = createAsyncThunk<SliderListResponse, void, AsyncThunkConfig>(
    'sliders/fetchAll',
    async (argument, thunkAPI) => {
        try {
            const result = await thunkAPI.extra.sliders.fetchAll();
            return result;
        } catch (err: any) {
            return HandleAsyncActionError(err, thunkAPI);
        }
    },
);


// ----- //
// Slice //
// ----- //

export const slidersSlice = createSlice({
    name: 'sliders',
    initialState,
    reducers: {
        // -------------- //
        // Sync Reducers //
        // -------------- //

        clearSliders: (state, action: PayloadAction<void>) =>
            Object.assign(state, initialState),
    },
    extraReducers: builder => {
        // -------------- //
        // Async Reducers //
        // -------------- //

        
        AddActionCases(
            builder,
            fetchSliders,
            (state, action) => {
                state.sliderList = action.payload;
            },
            (state, action) => {
                state.sliderList = null;
            },
            (state, action) => {},
        );
    },
});

// ------------ //
// Sync Actions //
// ------------ //
export const { clearSliders } = slidersSlice.actions;

export const sliderSelector = (state: RootState) => state.sliders;
export default slidersSlice.reducer;
