import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';

interface ExpandableTextProps {
  text?: string;
  limit: number;
  initiallyExpanded?: boolean;
}

const ExpandableText: React.FC<ExpandableTextProps> = ({ text, limit, initiallyExpanded = false }) => {
    const [isExpanded, setIsExpanded] = useState(initiallyExpanded);

    if(text === undefined || text === null) text = '';

    const toggleExpanded = () => setIsExpanded(!isExpanded);
  
    const shouldShowLink = text.length > limit && !initiallyExpanded;
    const previewText = text.slice(0, limit);

  return (
    <>
      <ReactMarkdown className="text-muted mb-4">
        {isExpanded || !shouldShowLink ? text : `${previewText}...`}
      </ReactMarkdown>
      {shouldShowLink && (
        <button onClick={toggleExpanded} className="btn btn-link mb-4" style={{ padding: 0 }}>
            {isExpanded ? 'Show Less' : 'Show More'}
        </button>
      )}
    </>
  );
};

export default ExpandableText;