export enum UserType {
    User = 'user',
    Influencer = 'influencer',
    Agency = 'agency',
    Admin = 'admin',
}

export enum OrderBy {
    NAME = 'name',
    NEWEST = 'newest',
    OLDEST = 'oldest',
}