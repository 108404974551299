import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { AsyncThunkConfig, RootState } from '../store';
import { SocialPlatformListResponse } from '../../api/social_platforms/types';

import {
    AddActionCases,
    GenericSliceState,
    HandleAsyncActionError,
} from '../../utils/helpers/reduxToolkitHelper';

export interface socialPlatformsState extends GenericSliceState {
    isLoading: boolean;
    errorCode: string | null;
    errorMessage: string;

    socialPlatforms: SocialPlatformListResponse | null;
    isLoadingSP: boolean;
    errorCodeSP: string | null;
    errorMessageSP: string;
}

export const initialState: socialPlatformsState = {
    isLoading: false,
    errorCode: null,
    errorMessage: '',

    socialPlatforms: null,
    isLoadingSP: false,
    errorCodeSP: null,
    errorMessageSP: '',
};

// ------------- //
// Async Actions //
// ------------- //

export const fetchAll = createAsyncThunk<SocialPlatformListResponse, void, AsyncThunkConfig>(
    'socialPlatforms/fetchAll',
    async (_, thunkAPI) => {
        try {
            const result = await thunkAPI.extra.socialPlatforms.fetchAll();
            return result;
        } catch (err: any) {
            return HandleAsyncActionError(err, thunkAPI);
        }
    },
);

// ----- //
// Slice //
// ----- //

export const socialPlatformsSlice = createSlice({
    name: 'socialPlatforms',
    initialState,
    reducers: {
        // -------------- //
        // Sync Reducers //
        // -------------- //

        clearSocialPlatforms: (state, action: PayloadAction<void>) =>
            Object.assign(state, initialState),
    },
    extraReducers: (builder) => {
        // -------------- //
        // Async Reducers //
        // -------------- //

        
        AddActionCases(
            builder,
            fetchAll,
            (state, action) => {
                state.socialPlatforms = action.payload;
            },
            (state, action) => {
                state.socialPlatforms = null;
            },
            (state, action) => {},
            'SP'
        );

    },
});

// ------------ //
// Sync Actions //
// ------------ //
export const { clearSocialPlatforms } = socialPlatformsSlice.actions;

export const socialPlatformsSelector = (state: RootState) => state.socialPlatforms;
export default socialPlatformsSlice.reducer;
