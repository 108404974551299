import axiosInstance from '../axiosInstances';
import { UserListResponse, User, FetchParams, CheckUsernameResponse } from './types';

const users = {
  getByIdentifier: async (identifier:string): Promise<User> => {
    const response = await axiosInstance.get(`/users/${identifier}`);
    return response.data.data as User;
  },
  fetchAll: async (params?: FetchParams): Promise<UserListResponse> => {
    const response = await axiosInstance.get(`/users`, {
      params,
    });
    return response.data as UserListResponse;
  },
  fetchCursor: async (cursor:string): Promise<UserListResponse> => {
    const response = await axiosInstance.get(`${cursor}`);
    return response.data as UserListResponse;
  },
  updateUser: async (userId: number, params: FormData | Partial<User>, method: string): Promise<User> => {
    let response;
    if(method === 'put'){
      response = await axiosInstance.put(`/users/${userId}`, params);
    }else{
      response = await axiosInstance.post(`/users/${userId}`, params);
    }
    return response.data.data as User;
  },
  checkUsername: async (username:string): Promise<CheckUsernameResponse> => {
    const response = await axiosInstance.get(`/users/available/${username}`);
    return response.data as CheckUsernameResponse;
  },
  updateSocialPlatform: async (userId: number, platforms: { code: string, value: string }[]): Promise<User> => {
    const response  = await axiosInstance.post(`/users/${userId}/social_platforms`, {platforms});
    return response.data.data as User;
  },
};

export default users;