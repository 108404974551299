import { Collection } from 'api/collections/types';
import React, { createContext, useContext } from 'react';

const CollectionContext = createContext<Collection | null>(null);
export const useCollection = () => useContext(CollectionContext);

interface CollectionProviderProps {
  collection: Collection;
  children: React.ReactNode;
}

const CollectionProvider : React.FC<CollectionProviderProps> = ({ collection, children }) => {
  return (
    <CollectionContext.Provider value={collection}>
      {children}
    </CollectionContext.Provider>
  );
};
export default CollectionProvider;