import React, { ReactNode } from 'react';
import { createWeb3Modal } from '@web3modal/wagmi/react';
import { State, WagmiProvider } from 'wagmi';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import Web3ModalConfig from './Web3ModalConfig';

// Setup queryClient
const queryClient = new QueryClient();

const projectId = process.env.REACT_APP_WALLETCONNECT_PROJECT_ID || '';

// modal
createWeb3Modal({
  wagmiConfig: Web3ModalConfig,
  projectId,
  enableAnalytics: true,
  enableOnramp: true,
});

interface Web3ModalProviderProps {
    children: ReactNode;
    initialState?: State;
}

export function Web3ModalProvider({ children, initialState }: Web3ModalProviderProps) {
  return (
    <WagmiProvider config={Web3ModalConfig} initialState={initialState}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </WagmiProvider>
  );
}