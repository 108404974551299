import { formatUnits } from "viem";

export const formatSalePrice = (price: any, currency: string = 'USD'): string => {
    const numericPrice = typeof price === 'string' ? parseFloat(price) : price;

    if (isNaN(numericPrice)) {
        return `Invalid price`;
    }

    // return `${numericPrice.toFixed(2)} ${currency}`;
    return `${numericPrice.toFixed(5)} ${currency}`;
};

export const formatBalance = (value: any, symbol: string = 'ETH', decimals: number = 18): string => {
    // const etherValue = Number(price) / Math.pow(10, decimals);
    // return `${etherValue.toFixed(3)} ${symbol}`;
    return `${Number(formatUnits(value, decimals)).toFixed(4)} ${symbol}`
};

export const shortenAddress = (address: string) : string => {
    return `${address.slice(0, 6)}...${address.slice(-4)}`;
};

export const shortenText = (text: string, length: number = 12, slice: number = 10, chars:string = '...'):string => {
    return `${(text.length > length ? text.slice(0, slice) + chars : text)}`;
};