export enum OrderBy {
    NAME = 'name',
    RANDOM = 'random',
}

export enum TraitsDisplayType {
    NONE = 'none',
    NUMBER = 'number',
    BOOST_PERCENTAGE = 'boost_percentage',
    BOOST_NUMBER = 'boost_number',
    AUTHOR = 'author',
    DATE = 'date',
}