import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { AsyncThunkConfig, RootState } from '../store';
import { User, UserListResponse, FetchParams, CheckUsernameResponse } from '../../api/users/types';

import {
    AddActionCases,
    GenericSliceState,
    HandleAsyncActionError,
} from '../../utils/helpers/reduxToolkitHelper';
import { OrderBy } from 'api/users/enums';

export interface usersState extends GenericSliceState {
    searchUsers: UserListResponse | null;
    users: UserListResponse | null;
    user: User | null;
    orderBy: string;
    checkedUsername: CheckUsernameResponse | null;

    isLoading: boolean;
    errorCode: string | null;
    errorMessage: string;

    isLoadingCheckUsername: boolean;
    errorCodeCheckUsername: string | null;
    errorMessageCheckUsername: string;

    isLoadingSearch: boolean;
    errorCodeSearch: string | null;
    errorMessageSearch: string;
}

export const initialState: usersState = {
    searchUsers: null,
    users: null,
    user: null,
    orderBy: OrderBy.NAME,
    checkedUsername: null,

    isLoading: false,
    errorCode: null,
    errorMessage: '',

    isLoadingCheckUsername: false,
    errorCodeCheckUsername: null,
    errorMessageCheckUsername: '',

    isLoadingSearch: false,
    errorCodeSearch: null,
    errorMessageSearch: '',
};

// ------------- //
// Async Actions //
// ------------- //

export const getByIdentifier = createAsyncThunk<User, { identifier: string }, AsyncThunkConfig>(
    'users/getByIdentifier',
    async ({ identifier }, thunkAPI) => {
        try {
            const result = await thunkAPI.extra.users.getByIdentifier(identifier);
            return result;
        } catch (err: any) {
            return HandleAsyncActionError(err, thunkAPI);
        }
    },
);

export const fetchAll = createAsyncThunk<UserListResponse, { params: FetchParams }, AsyncThunkConfig>(
    'users/fetchAll',
    async ({ params }, thunkAPI) => {
        try {
            const result = await thunkAPI.extra.users.fetchAll(params);
            return result;
        } catch (err: any) {
            return HandleAsyncActionError(err, thunkAPI);
        }
    },
);

export const fetchSearch = createAsyncThunk<UserListResponse, { params: FetchParams }, AsyncThunkConfig>(
    'users/fetchSearch',
    async ({ params }, thunkAPI) => {
        try {
            const result = await thunkAPI.extra.users.fetchAll(params);
            return result;
        } catch (err: any) {
            return HandleAsyncActionError(err, thunkAPI);
        }
    },
);

export const fetchCursor = createAsyncThunk<UserListResponse, { cursor: string }, AsyncThunkConfig>(
    'users/fetchCursor',
    async ({ cursor }, thunkAPI) => {
        try {
            const result = await thunkAPI.extra.users.fetchCursor(cursor);
            return result;
        } catch (err: any) {
            return HandleAsyncActionError(err, thunkAPI);
        }
    },
);

export const updateUser = createAsyncThunk<User, { userId: number; params: FormData | Partial<User>, method?: string }, AsyncThunkConfig>(
    'users/updateUser',
    async ({ userId, params, method = 'put' }, thunkAPI) => {
        try {
            const result = await thunkAPI.extra.users.updateUser(userId, params, method);
            return result;
        } catch (err: any) {
            return thunkAPI.rejectWithValue(err.response.data);
        }
    },
);

export const checkUsername = createAsyncThunk<CheckUsernameResponse, { username: string }, AsyncThunkConfig>(
    'users/checkUsername',
    async ({ username }, thunkAPI) => {
        try {
            const result = await thunkAPI.extra.users.checkUsername(username);
            return result;
        } catch (err: any) {
            return HandleAsyncActionError(err, thunkAPI);
        }
    },
);

export const updateSocialPlatform = createAsyncThunk<User, { userId: number, platforms: { code: string, value: string }[] }, AsyncThunkConfig>(
    'users/updateSocialPlatform',
    async ({ userId, platforms }, thunkAPI) => {
        try {
            const result = await thunkAPI.extra.users.updateSocialPlatform(userId, platforms);
            return result;
        } catch (err: any) {
            return HandleAsyncActionError(err, thunkAPI);
        }
    },
);

// ----- //
// Slice //
// ----- //

export const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        // -------------- //
        // Sync Reducers //
        // -------------- //

        clearUsers: (state, action: PayloadAction<void>) =>
            Object.assign(state, initialState),

        setOrderBy: (state, action: PayloadAction<string>) => {
            state.orderBy = action.payload;
        },
    },
    extraReducers: (builder) => {
        // -------------- //
        // Async Reducers //
        // -------------- //

        AddActionCases(
            builder,
            getByIdentifier,
            (state, action) => {
                state.user = action.payload;
            },
            (state, action) => {
                state.user = null;
            },
            (state, action) => {},
        );
        
        AddActionCases(
            builder,
            fetchAll,
            (state, action) => {
                state.users = action.payload;
            },
            (state, action) => {
                state.users = null;
            },
            (state, action) => {},
        );

        AddActionCases(
            builder,
            fetchCursor,
            (state, action) => {
                if (state.users) {
                    state.users.data = [...state.users.data, ...action.payload.data];
                    state.users.links = action.payload.links;
                    state.users.meta = action.payload.meta;
                } else {
                    state.users = action.payload;
                }
            },
            (state, action) => {},
            (state, action) => {},
        );

        AddActionCases(
            builder,
            updateUser,
            (state, action) => {
                state.user = action.payload;
            },
            (state, action) => {
                state.user = null;
            },
            (state, action) => {},
        );

        AddActionCases(
            builder,
            checkUsername,
            (state, action) => {
                state.checkedUsername = action.payload;
            },
            (state, action) => {
                state.checkedUsername = null;
            },
            (state, action) => {},
            'CheckUsername'
        );

        AddActionCases(
            builder,
            updateSocialPlatform,
            (state, action) => {
                state.user = action.payload;
            },
            (state, action) => {
                state.user = null;
            },
            (state, action) => {},
        );

        AddActionCases(
            builder,
            fetchSearch,
            (state, action) => {
                state.searchUsers = action.payload;
            },
            (state, action) => {
                state.searchUsers = null;
            },
            (state, action) => {},
            'Search'
        );

    },
});

// ------------ //
// Sync Actions //
// ------------ //
export const { clearUsers, setOrderBy } = usersSlice.actions;

export const usersSelector = (state: RootState) => state.users;
export default usersSlice.reducer;
