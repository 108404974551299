import React from 'react'
import { Link } from 'react-router-dom'
import error from '../../assets/images/error.png'
import BackToHome from '../../components/back-to-home'
import Navbar from 'components/navbar'
import Footer from 'components/footer'
import { useTranslation } from 'react-i18next';

export default function NotFound() {
  const { t } = useTranslation();
  return (
    <>
    <Navbar navlight={false}/>
    <section className="position-relative bg-soft-primary mt-5">
      <div className="container-fluid">
          <div className="row">
              <div className="col-12 p-0">
                  <div className="d-flex flex-column min-vh-100 p-4">
                      <div className="title-heading text-center my-auto">
                          <img src={error} className="img-fluid" alt=""/>
                          <h1 className="heading sub-heading mb-3 mt-5 text-dark">{t('404.labels.title')}</h1>
                          <p className="text-muted" dangerouslySetInnerHTML={{ __html: t('404.labels.subtitle') }} />
                          
                          <div className="mt-4">
                              <Link to="/" className="back-button btn btn-dark">{t('404.labels.back')}</Link>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    </section>
    <BackToHome/>
    <div className="mt-100"></div>
    <Footer />
    </>
  )
}
