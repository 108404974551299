import { Navigate, Outlet } from 'react-router-dom';
import React from 'react';
import { history } from '../utils/helpers/historyHelper';
import { useAppContext } from 'context/AppContextProvider';

const PrivateRoute = () => {
    const { loading, isWalletConnecting, isAuthenticated } = useAppContext();
    
    // @debug
    // console.log('PrivateRoute loading, isAuthenticated', loading, isAuthenticated);

    if (loading || isWalletConnecting) {
        return null;
    }

    if (!isAuthenticated) {
        // not logged in so redirect to login page with the return url
        return <Navigate to="/" state={{ from: history.location }} />;
    }

    // authorized so return child components
    return <Outlet />;
};

export default PrivateRoute;