import Navbar from 'components/navbar';
import React from 'react';
import BestInfluencers from 'components/creater/best-influencers';
import { DisplayThemeProps } from 'interfaces/category/DisplayThemeProps';
import CategoryCollection from 'components/collection/category-collection';
import Footer from 'components/footer';
import { Helmet } from 'react-helmet-async';

const DisplayTheme4: React.FC<DisplayThemeProps> = (props) => {
    return (
        <>
        <Helmet>
            <title>{props.category.name} - {process.env.REACT_APP_NAME}</title>
            <script type="application/ld+json">
                {JSON.stringify({
                    '@context': 'https://schema.org',
                    '@type': 'WebSite',
                    name: process.env.REACT_APP_NAME,
                    url: window.location.origin
                })}
            </script>
            <script type="application/ld+json">
                {JSON.stringify({
                    '@context': 'https://schema.org',
                    '@type': 'Organization',
                    name: process.env.REACT_APP_NAME,
                    url: window.location.origin,
                    logo: window.location.origin + '/assets/images/logo-light.webp',
                })}
            </script>
        </Helmet>
        <Navbar navlight={true}/>
        <section className="bg-half-170 d-table w-100 position-relative">
            <video autoPlay loop muted playsInline className="w-100 h-100 position-absolute top-0 start-0" style={{ objectFit: 'cover', zIndex: -1 }}>
                <source src="https://res.cloudinary.com/dwzrr6u1q/video/upload/f_auto:video,q_auto/v1/theme/assets/videos/aqnmcpqj3krkv3gkyh1h" type="video/webm" />
                <source src="https://res.cloudinary.com/dwzrr6u1q/video/upload/v1730141892/theme/assets/videos/sports-mp4.mp4" type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            <div className="container">
                <div className="row mt-5 justify-content-center">
                    <div className="col-12">
                        <div className="title-heading text-center">
                        <h5 className="heading fw-semibold sub-heading text-white title-dark">&nbsp;</h5>
                        <p className="text-white-50 para-desc mx-auto mb-0">&nbsp;</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="section">
            <BestInfluencers params={{
                category_slug: props.category.slug
            }} />
            <CategoryCollection params={{
                category_slug: props.category.slug,
                collection_fields: 'owner,categories,stages,contracts'
            }} />
        </section>
        <Footer/>
        </>
    );
};

export default DisplayTheme4;