import { MessageResponse } from 'api/common.types';
import axiosInstance from '../axiosInstances';

const suscribers = {
  createSuscriber: async (email: string): Promise<MessageResponse> => {
    const response = await axiosInstance.post(`/suscribers`, {email});
    return response.data as MessageResponse;
  },
};

export default suscribers;