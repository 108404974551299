import React, { useEffect, useState } from 'react';

interface QuantitySelectorProps {
  min: number;
  max: number;
  onChange: (value: number) => void;
}

const CollectionQuantitySelector: React.FC<QuantitySelectorProps> = ({ min, max, onChange }) => {
  let [quantity, setQuantity] = useState<number>(min);
  let [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);

  useEffect(() => {
    onChange(quantity);
  }, [quantity, onChange]);

  // Clean up interval on component unmount
  useEffect(() => {
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [intervalId]);

  const updateQuantity = (change: number) => {
    setQuantity((prevQuantity) => Math.min(Math.max(prevQuantity + change, min), max));
  };

  const handleMouseDown = (change: number) => {
    updateQuantity(change);
    const id = setInterval(() => updateQuantity(change), 100);
    setIntervalId(id);
  };

  const handleMouseUp = () => {
    if (intervalId) {
      clearInterval(intervalId);
      setIntervalId(null);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;

    if (inputValue === '') {
      setQuantity(0);
      return;
    }

    let value = parseInt(inputValue, 10);
    if (!isNaN(value)) {
      value = Math.min(Math.max(value, min), max);
      setQuantity(value);
    }
  };

  return (
    <div className="quantity-selector">
      <button type="button"
      onMouseDown={() => handleMouseDown(-1)}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
      disabled={quantity <= min}>-</button>
      <input
        type="number"
        name="quantity"
        min={min}
        max={max}
        value={quantity}
        onChange={handleChange}
      />
      <button type="button"
      onMouseDown={() => handleMouseDown(1)}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
      disabled={quantity >= max}>+</button>
    </div>
  );
};

export default CollectionQuantitySelector;