import axiosInstance from '../axiosInstances';
import { CollectionListResponse, TrendingParams } from './types';

const stats = {
  collections: {
    trending: async (params?: TrendingParams): Promise<CollectionListResponse> => {
      const response = await axiosInstance.get(`/stats/collections/trending`, {
        params,
      });
      return response.data as CollectionListResponse;
    },
  }
};

export default stats;