import React, { useEffect } from 'react';
import { Collection, CollectionTraits, CollectionTraitsCategories } from '../../../api/collections/types';
import { OrderBy } from '../../../api/tokens/enums';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store/store';
import { fetchTraits } from '../../../store/collections/collections.slice';
import { setOrderBy, setTraits } from '../../../store/tokens/tokens.slice';
import { useTranslation } from 'react-i18next';

interface CollectionItemsFilterProps {
    collection: Collection;
}

const CollectionItemsFilter : React.FC<CollectionItemsFilterProps> = ({ collection }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const { traits_categories } = useSelector((state: RootState) => state.collections);
  const { orderBy, traits } = useSelector((state: RootState) => state.tokens);

  useEffect(() => {
    dispatch(fetchTraits({ identifier: collection.slug }));
  }, [dispatch, collection]);

  const handleOrderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setOrderBy(e.target.value as OrderBy));
  };

  const handleTraitChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    dispatch(
      setTraits(
        traits.includes(value) ? traits.filter(attr => attr !== value) : [...traits, value]
      )
    );
  };

    return (
      <div className="sticky-bar">
        <h5 className="mb-0">{t('collection.fields.filters')}</h5>
        <div className="p-4 rounded-md shadow mt-4">
          <div>
            <h6>{t('collection.fields.order_by')}</h6>
            <div className="form-check align-items-center d-flex mb-0">
              <input type="radio" id="nameOrder" value="name" checked={orderBy === 'name'} onChange={handleOrderChange} className="form-check-input" />
              <label className="form-check-label fw-bold ms-2" htmlFor="nameOrder">{t('collection.fields.order_by_1')}</label>
            </div>
            <div className="form-check align-items-center d-flex mb-0">
              <input type="radio" id="newOrder" value="newest" checked={orderBy === 'newest'} onChange={handleOrderChange} className="form-check-input" />
              <label className="form-check-label fw-bold ms-2" htmlFor="newOrder">{t('collection.fields.order_by_2')}</label>
            </div>
            <div className="form-check align-items-center d-flex mb-0">
              <input type="radio" id="oldestOrder" value="oldest" checked={orderBy === 'oldest'} onChange={handleOrderChange} className="form-check-input" />
              <label className="form-check-label fw-bold ms-2" htmlFor="oldestOrder">{t('collection.fields.order_by_3')}</label>
            </div>
          </div>

          {traits_categories && traits_categories.data.map((category: CollectionTraitsCategories) => (
            <div key={`c-${category.id}`} className="mt-4">
              <h6 style={{textTransform: 'capitalize'}}>{category.name}</h6>
              {category.traits && category.traits.map((trait: CollectionTraits) => (
                <div key={`t-${trait.id}`} className="form-check align-items-center d-flex mb-0">
                  <input
                    type="checkbox"
                    id={`trait-${trait.id}`}
                    value={trait.id}
                    checked={traits.includes(trait.id)}
                    onChange={handleTraitChange}
                    className="form-check-input cursor-pointer"
                  />
                  <label style={{textTransform: 'capitalize'}} className="form-check-label fw-bold ms-2 cursor-pointer" htmlFor={`trait-${trait.id}`}>{trait.value} ({trait.tokens_count})</label>
                </div>
              ))}
            </div>
          ))}
        
        </div>
      </div>
    );
};

export default CollectionItemsFilter;