import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

interface ImageProps {
    id?: string | undefined;
    src: string;
    alt: string;
    height?: string;
    width?: string;
    className: string;
    style?: React.CSSProperties;
}

const LazyImage: React.FC<ImageProps> = (image) => {
    return(
    <LazyLoadImage
        id={image.id ?? ''}
        className={image.className}
        alt={image.alt}
        src={image.src}
        placeholder={
            <div className="d-flex justify-content-center align-items-center w-100 h-100" style={{ minHeight: image.height ?? '100px' }}>
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
        }
        effect="blur"
        wrapperProps={{
            // If you need to, you can tweak the effect transition using the wrapper style.
            style: {transitionDelay: "1s"},
        }}
        style={image.style}
        />
        // <img
        //     alt={alt}
        //     src={`${src}?a=1`}
        //     sizes="(max-width: 20rem) 100vw, (max-width: 30rem) 50vw, (max-width: 50rem) 33.33vw, (max-width: 60rem) 25vw, (max-width: 1024px) 20vw, (max-width: 70rem) 33.33vw, (max-width: 90rem) 25vw, (max-width: 110rem) 20vw, (max-width: 130rem) 16.67vw, (max-width: 150rem) 14.29vw, 12.5vw"
        //     srcSet={srcSet}
        //     loading="lazy"
        //     decoding="async"
        //     className={className}
        //     />
    )
};

export default LazyImage;