import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom'
import { fetchCategoryCollections } from '../../store/collections/collections.slice';
import { AppDispatch, RootState } from '../../store/store';
import Navbar from 'components/navbar';
import BestInfluencers from 'components/creater/best-influencers';
import { DisplayThemeProps } from 'interfaces/category/DisplayThemeProps';

import TinySlider from "tiny-slider-react";
import 'tiny-slider/dist/tiny-slider.css';
import { Collection } from 'api/collections/types';
import { Category } from 'api/categories/types';
import Footer from 'components/footer';
import { getNextStage } from 'utils/helpers/collectionHelper';
import { formatSalePrice } from 'utils/helpers/formatHelper';
import { useTranslation } from 'react-i18next';
import LazyImage from 'components/lazy-image';
import CategoryBadge from 'components/collection/category-badge';
import { Helmet } from 'react-helmet-async';

const DisplayTheme2: React.FC<DisplayThemeProps> = (props) => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const { categoryCollections } = useSelector((state: RootState) => state.collections);
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    
    useEffect(() => {
        dispatch(fetchCategoryCollections({
            category_slug: props.category.slug,
            collection_fields: 'owner,categories,stages,contracts'
        }));
    }, [dispatch, props]);

    

  const settings = {
    container: '.tiny-five-item',
    controls: false,
    mouseDrag: true,
    loop: true,
    rewind: true,
    autoplay: true,
    autoplayButtonOutput: false,
    autoplayTimeout: 3000,
    navPosition: "bottom",
    speed: 400,
    gutter: 12,
    responsive: {
        1025: {
            items: 5
        },

        992: {
            items: 3
        },

        767: {
            items: 2
        },

        320: {
            items: 1
        },
    },
  };

    return categoryCollections && (
        <>
        <Helmet>
            <title>{props.category.name} - {process.env.REACT_APP_NAME}</title>
            <script type="application/ld+json">
                {JSON.stringify({
                    '@context': 'https://schema.org',
                    '@type': 'WebSite',
                    name: process.env.REACT_APP_NAME,
                    url: window.location.origin
                })}
            </script>
            <script type="application/ld+json">
                {JSON.stringify({
                    '@context': 'https://schema.org',
                    '@type': 'Organization',
                    name: process.env.REACT_APP_NAME,
                    url: window.location.origin,
                    logo: window.location.origin + '/assets/images/logo-light.webp',
                })}
            </script>
        </Helmet>
        {/* <div className="round-effect">
            <div className="primary-round opacity-3"></div>
            <div className="gradient-round opacity-3"></div>
        </div> */}
        <div className="round-effect">
            <div className="primary-round opacity-5 w-100" style={{borderRadius: '0', filter: 'unset',
              backgroundImage: 'url(https://static.wixstatic.com/media/f878f0_85151d09e21d4ae08ef686716b92a83d~mv2.png/v1/fill/w_1440,h_719,al_c,q_90,usm_0.66_1.00_0.01,enc_auto/f878f0_85151d09e21d4ae08ef686716b92a83d~mv2.png)',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat'
            }}></div>
        </div>
        <Navbar navlight={false}/>
        <section className="bg-half-174">
          <div className="container-fluid">
              <div className="row">
                  <div className="col-12">
                      <div className="tiny-five-item">
                        <TinySlider settings={settings}>
                          {categoryCollections.data.map((item: Collection,index)=>{
                            const stage = getNextStage(item?.stages || [], userTimeZone);
                            const contract = item?.contracts && item.contracts.length > 0 ? item.contracts[0] : null;
                            return(
                              <div className="tiny-slide" key={index}>
                                <div className="card bg-white nft-items nft-primary rounded-md shadow-md overflow-hidden mx-2 my-3">
                                    <div className="nft-image position-relative overflow-hidden" style={{maxHeight:'337px'}}>
                                        <Link to={`/collection/${item.slug}`}><LazyImage src={item.logo_permalink} className="img-fluid" alt={item.slug} height='258px' style={{objectFit:'cover'}}/></Link>
                                        {item.categories && item.categories.length > 0 && (
                                          <div className="position-absolute top-0 start-0 m-3">
                                              {item.categories.map((category: Category) => (
                                                <CategoryBadge key={category.id} category={category} />
                                              ))}
                                          </div>
                                        )}                               
                                    </div>
        
                                    <div className="card-body content position-relative">
                                        <Link to={`/collection/${item.slug}`} className="title text-dark h6">{item.name}</Link>
        
                                        <div className="d-flex align-items-center justify-content-between mt-3">
                                            {contract?.is_soldout ? (
                                              <small className="rate fw-bold">{t('collection.contracts.labels.soldout')}</small>
                                            ) : stage && (
                                              <small className="rate fw-bold">{formatSalePrice(stage.sale_price)}</small>
                                            )}
                                            <Link to={`/collection/${item.slug}`} className="btn btn-icon btn-pills btn-primary"><i className="uil uil-shopping-bag text-white"></i></Link>
                                        </div>
                                    </div>
                                </div>
                              </div>
                            )
                          })}
                        </TinySlider>
                      </div>
                  </div>
              </div>
          </div>
          <div className="container mt-100 mt-60">
            <BestInfluencers params={{
                  category_slug: props.category.slug
            }} />
          </div>
        </section>
        <Footer/>
        </>
    );
};

export default DisplayTheme2;