import axiosInstance from '../axiosInstances';
import { CategoryListResponse, FetchParams } from './types';

const categories = {
  fetchAll: async (params?: FetchParams): Promise<CategoryListResponse> => {
    const response = await axiosInstance.get(`/categories`, {
      params,
    });
    return response.data as CategoryListResponse;
  },
};

export default categories;