import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom'

import { fetchCollectionsTrending } from '../../store/stats/stats.slice';
import { AppDispatch, RootState } from '../../store/store';
import { Collection } from '../../api/collections/types';
import LazyImage from '../lazy-image';
import { formatSalePrice } from 'utils/helpers/formatHelper';
import { getNextStage } from 'utils/helpers/collectionHelper';
import { useTranslation } from 'react-i18next';

const TrendingCollection = () =>{
    const dispatch = useDispatch<AppDispatch>();
    const { t } = useTranslation();
    const { collections, isLoadingCollectionsTrending, errorMessageCollectionsTrending } = useSelector((state: RootState) => state.stats);
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    useEffect(() => {
        dispatch(fetchCollectionsTrending({top:10, collection_fields: 'owner,stages,contracts'}));
    }, [dispatch]);

    const leftCollections = collections.trending?.data.slice(0, 5);
    const rightCollections = collections.trending?.data.slice(5, 10);

    if (errorMessageCollectionsTrending) {
        return <div>Error: {errorMessageCollectionsTrending}</div>;
    }


    return (
        <div className="container mt-100 mt-60">
            <div className="row">
                <div className="col-12">
                    <div className="section-title">
                        <h4 className="title mb-2">{t('collection.trending.labels.title')}</h4>
                    </div>
                </div>
            </div>
            <div className="d-block d-lg-none">
                <div className="row fw-bold text-muted">
                    <div className="col-2">{t('collection.trending.labels.rank')}</div>
                    <div className="col-10">{t('collection.trending.labels.collections')}</div>
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-lg-6">
                <div className="d-none d-lg-flex fw-bold text-muted">
                    <div className="col-2">{t('collection.trending.labels.rank')}</div>
                    <div className="col-10">{t('collection.trending.labels.collections')}</div>
                </div>
                {leftCollections && leftCollections.map((item: Collection, index) => {
                    const stage = getNextStage(item?.stages || [], userTimeZone);
                    const contract = item?.contracts && item.contracts.length > 0 ? item.contracts[0] : null;
                    return (
                    <div className="d-flex align-items-center mt-3" key={`tc-${item.id}`}>
                        <div className="col-2 fw-bold text-muted">{index + 1}.</div>
                        <div className="col-10 d-flex align-items-center">
                            <Link to={`/collection/${item.slug}`}><LazyImage src={item.logo_permalink} className="avatar avatar-md-sm shadow-md rounded-pill" alt={item.slug}  height='54px' /></Link>
                            <div className="ms-3">
                            <h6 className="mb-0">
                                <Link to={`/collection/${item.slug}`} className="text-dark name">{item.name}</Link>
                            </h6>
                            {contract?.is_soldout ? (
                                <small className="text-muted">{t('collection.contracts.labels.soldout')}</small>
                            ) : stage && (
                                <small className="text-muted">{formatSalePrice(stage.sale_price)}</small>
                            )}
                            </div>
                        </div>
                    </div>
                )})}
                {isLoadingCollectionsTrending && Array.from({ length: 5 }).map((_, index) => (
                    <div className="d-flex align-items-center mt-3" key={`tc-${index}`}>
                        <div className="col-2 fw-bold text-muted placeholder-glow">
                            <span className="placeholder col-1"></span>
                        </div>
                        <div className="col-10 d-flex align-items-center">
                            <div className="placeholder rounded-pill avatar avatar-md-sm shadow-md"></div>
                            <div className="ms-3 placeholder-glow">
                                <h6 className="mb-0">
                                    <span className="placeholder col-7"></span>
                                </h6>
                                <small className="placeholder col-3"></small>
                            </div>
                        </div>
                    </div>
                ))}
                </div>
                {rightCollections && rightCollections.length > 0 && (
                    <div className="col-12 col-lg-6">
                        <div className="d-none d-lg-flex fw-bold text-muted">
                            <div className="col-2">{t('collection.trending.labels.rank')}</div>
                            <div className="col-10">{t('collection.trending.labels.collections')}</div>
                        </div>
                        {rightCollections?.map((item: Collection, index) => {
                            const stage = getNextStage(item?.stages || [], userTimeZone);
                            const contract = item?.contracts && item.contracts.length > 0 ? item.contracts[0] : null;
                            return (
                            <div className="d-flex align-items-center mt-3" key={`tc-${item.id}`}>
                                <div className="col-2 fw-bold text-muted">{index + 6}.</div>
                                <div className="col-10 d-flex align-items-center">
                                    <Link to={`/collection/${item.slug}`}><LazyImage src={item.logo_permalink} className="avatar avatar-md-sm shadow-md rounded-pill" alt={item.slug} height='54px' /></Link>
                                    <div className="ms-3">
                                        <h6 className="mb-0">
                                            <Link to={`/collection/${item.slug}`} className="text-dark name">{item.name}</Link>
                                        </h6>
                                        {contract?.is_soldout ? (
                                            <small className="text-muted">{t('collection.contracts.labels.soldout')}</small>
                                        ) : stage && (
                                            <small className="text-muted">{formatSalePrice(stage.sale_price)}</small>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )})}
                    </div>
                )}
            </div>
        </div>
    )
}

export default TrendingCollection;