import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { AppDispatch, RootState } from '../store/store';
import { setLanguage } from 'store/language/language.slice';
import i18n from '../utils/i18n/i18n';
import { useNavigate } from 'react-router-dom';
import { clearCache, setCacheItem } from '../utils/helpers/cacheHelper';
import flagEn from '../assets/images/lang/en.svg';
import flagEs from '../assets/images/lang/es.svg';
import { fetchCategoriesMenu } from '../store/categories/categories.slice';
import { FiltersYesNo } from '../enums/filters';
import { fetchSliders } from 'store/sliders/sliders.slice';

interface NavbarLangProps {
    navdropdown: boolean;
}

const NavbarLanguage: React.FC<NavbarLangProps> = (props)=> {
    const dispatch = useDispatch<AppDispatch>();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { language } = useSelector((state: RootState) => state.language);
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const menuRef = useRef<HTMLDivElement | null>(null);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (menuRef.current && event.target instanceof Node && !menuRef.current.contains(event.target)) {
          setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
        document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);
    
    const handleLanguageChange = async (lng: string) => {
        setIsLoading(true);

        clearCache({ prefix: 'categoriesMenuList', duration: 0});
        i18n.changeLanguage(lng);

        await dispatch(setLanguage(lng));
        
        dispatch(fetchCategoriesMenu({ show_menu: FiltersYesNo.YES })).then((response) => {
            if (response.payload) {
                setCacheItem('categoriesMenuList', response.payload);
            }
        });
        dispatch(fetchSliders());

        setIsOpen(false);
        setIsLoading(false);
        navigate('/');
    };

    return (
    <>
        {props.navdropdown ? 
            <div className="dropdown dropdown-primary" ref={menuRef}>
                <button type="button" className="btn btn-pills dropdown-toggle p-0" onClick={toggleMenu}>
                <img src={(language === 'en' ? flagEn : flagEs)} style={{maxHeight:'20px', height:'100%', width:'26px'}} alt={language}/>
                </button>
                {isOpen && (
                <div className="dropdown-menu dd-menu dropdown-menu-end bg-white shadow border border-light mt-3 pb-3 pt-0 overflow-hidden rounded d-block  end-0">
                    <div className="mt-3">
                        <button type="button" disabled={isLoading} onClick={() => handleLanguageChange('en')} className={`dropdown-item small fw-semibold text-dark d-flex align-items-center ${language === 'en' ? 'active' : ''}`}>
                            {isLoading ?
                                <>
                                <i className="uil uil-process mdi-spin me-1"></i> {t('common.buttons.load_more.loading')}
                                </>
                                :
                                <>
                                <span className="mb-0 d-inline-block me-1"><img src={flagEn} style={{maxHeight:'20px', height:'100%', width:'26px'}} alt="en"/></span> {t('lang.en')}
                                </>
                            }
                        </button>
                        <button type="button" disabled={isLoading} onClick={() => handleLanguageChange('es')} className={`dropdown-item small fw-semibold text-dark d-flex align-items-center mt-2 ${language === 'es' ? 'active' : ''}`}>
                        {isLoading ?
                                <>
                                <i className="uil uil-process mdi-spin me-1"></i> {t('common.buttons.load_more.loading')}
                                </>
                                :
                                <>
                                <span className="mb-0 d-inline-block me-1"><img src={flagEs} style={{maxHeight:'20px', height:'100%', width:'26px'}} alt="en"/></span> {t('lang.es')}
                                </>
                            }
                        </button>
                    </div>
                </div>
                )}
            </div>
        :
        <>
            <button type="button" disabled={isLoading} onClick={() => handleLanguageChange('en')} className={`btn small fw-semibold text-dark d-flex align-items-center ${language === 'en' ? 'active' : ''}`}>
                {isLoading ?
                    <>
                    <i className="uil uil-process mdi-spin me-1"></i> {t('common.buttons.load_more.loading')}
                    </>
                    :
                    <>
                    <span className="mb-0 d-inline-block me-1"><img src={flagEn} style={{maxHeight:'20px', height:'100%', width:'26px'}} alt="en"/></span> {t('lang.en')}
                    </>
                }
            </button>
            <button type="button" disabled={isLoading} onClick={() => handleLanguageChange('es')} className={`btn small fw-semibold text-dark d-flex align-items-center ${language === 'es' ? 'active' : ''}`}>
            {isLoading ?
                    <>
                    <i className="uil uil-process mdi-spin me-1"></i> {t('common.buttons.load_more.loading')}
                    </>
                    :
                    <>
                    <span className="mb-0 d-inline-block me-1"><img src={flagEs} style={{maxHeight:'20px', height:'100%', width:'26px'}} alt="en"/></span> {t('lang.es')}
                    </>
                }
            </button>
        </>
        }
    </>
    )
};

export default NavbarLanguage;