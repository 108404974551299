import React, { useEffect, useState, useRef } from 'react'
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'
import { RootState } from '../store/store';
import { Category } from '../api/categories/types';

import logoDark from '../assets/images/logo-dark.webp'
import logoLight from '../assets/images/logo-light.webp'
import logoWhite from '../assets/images/logo-white.webp'
import Modal from 'react-bootstrap/Modal';
import mataMask from '../assets/images/wallet/metamask.5801d957d27c65deeef0.png';
import NavbarProfile from './navbar-profile';

import SearchBox from './searchbox/searchbox';
import NavbarLanguage from './navbar-language';

interface NavbarProps {
    navlight: boolean;
    gradient?: boolean;
}

const Navbar: React.FC<NavbarProps> = ({navlight, gradient})=> {
    const { t } = useTranslation();
    const { categoriesMenuList, isLoadingMenu = true } = useSelector((state: RootState) => state.categories);
    const [show, setShow] = useState(false);
    let [manu, setManu] = useState('');
    let [toggle, setToggle] = useState(false)
    let [search, setSearch] = useState(false)
    let [scrolling, setScrolling] = useState(false);
    
    let current = window.location.pathname
    let searchRef = useRef<HTMLLIElement>(null)
    let userRef = useRef<HTMLLIElement>(null)

    useEffect(()=>{
        setManu(current);
        // setSubManu(current)

        const handleScroll = () => {
            const isScrolling = window.scrollY > 50;
            setScrolling(isScrolling);
        };

        const searchOutClick = (event: MouseEvent) =>{
            if(searchRef.current && !searchRef.current.contains(event.target as Node)) {
                setSearch(false)
            }
        }

        const userOutClick = (event: MouseEvent) => {
            if(userRef.current && !userRef.current.contains(event.target as Node)){
                // setUser(false)
            }
        }

        window.addEventListener('scroll', handleScroll);
        window.addEventListener('click', searchOutClick)
        window.addEventListener('click', userOutClick)
        window.scrollTo(0, 0)
        

        return()=>{
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('click', searchOutClick)
            window.removeEventListener('click', userOutClick)
        }
    },[current])
 
  return (
        <header id="topnav" className={`defaultscroll sticky ${scrolling ? 'nav-sticky' : ''} ${gradient ? 'gradient' : ''}`}>
            <div className="container">
                {navlight ? (
                    <Link className="logo" to="/">
                        <span className="logo-light-mode">
                            <img src={logoDark} height="35" className="l-dark" alt=""/>
                            <img src={logoWhite} height="35" className="l-light" alt=""/>
                        </span>
                        <img src={logoLight} height="35" className="logo-dark-mode" alt=""/>
                    </Link>
                ) : (
                    <Link className="logo" to="/">
                        <img src={logoDark} height="35" className="logo-light-mode" alt=""/>
                        <img src={logoLight} height="35" className="logo-dark-mode" alt=""/>
                    </Link>
                )}
                

                

                <div className="menu-extras me-1">
                    <div className="menu-item">
                        <button
                            style={{background:'transparent'}}
                            className={`navbar-toggle ${toggle ? 'open' : ''}`}
                            id="isToggle"
                            onClick={() => setToggle(!toggle)}
                            >
                            <div className="lines">
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </button>
                    </div>
                </div>

                <ul className="buy-button list-inline mb-0">
                    <li className="list-inline-item mb-0 me-1" ref={searchRef}>
                        <div className="dropdown">
                            {navlight ? (
                                <button type="button" className="btn dropdown-toggle p-0" onClick={(e)=>{setSearch(!search)}} >
                                    <i className="uil uil-search text-white title-dark btn-icon-light fs-5 align-middle"></i>
                                    <i className="uil uil-search text-dark btn-icon-dark fs-5 align-middle"></i>
                                </button>
                                ) : (
                                <button type="button" className="btn dropdown-toggle p-0"onClick={(e)=>{setSearch(!search)}} >
                                    <i className="uil uil-search text-dark fs-5 align-middle"></i>
                                </button>
                            )}
                            
                            
                            {search && (
                                <div className="dropdown-menu left-50 dd-menu d-block dropdown-menu-end bg-white shadow rounded border-0 mt-3 p-0 end-0" style={{width:'300px'}}>
                                    <div className="search-bar">
                                        <div id="itemSearch" className="menu-search mb-0">
                                            <SearchBox search={search} />
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </li>

                    <li className="list-inline-item mb-0 me-1 d-none d-md-inline-block">
                        <NavbarLanguage navdropdown={true} key='nl1' />
                    </li>
                    <li className="list-inline-item mb-0 me-1">
                        <NavbarProfile />
                    </li>
                </ul>
        
                <div id="navigation" style={{display : toggle ? 'block' : 'none'}}>
                    <ul className={`navigation-menu nav-left ${navlight ? 'nav-light' : ''}`}>
                        <li className={manu === '/' ? 'active' : ''}><Link to="/" className="sub-menu-item">{t('header.menu.home')}</Link></li>
                        {isLoadingMenu ? (
                            Array.from({ length: 4 }).map((_, index) => (
                            <li key={`placeholder-${index}`} className="placeholder-glow">
                                <Link to="/" className="sub-menu-item"><span className="placeholder col-4"></span></Link>
                            </li>
                            ))
                        ) : (
                            categoriesMenuList && categoriesMenuList.data.map((category:Category)=>(
                                <li key={`cnav-${category.id}`} className={manu === `/category/${category.slug}` ? 'active' : ''}><Link to={`/category/${category.slug}`} className="sub-menu-item">{category.name}</Link></li>
                            ))
                        )}
                        <li className="sub-menu-item border-top pt-3 mt-2 mb-3 d-flex align-items-center justify-content-center d-block d-md-none">
                            <NavbarLanguage navdropdown={false} key='nl2' />
                        </li>
                    </ul>
                </div>
            </div>
         <Modal show={show} onHide={()=>setShow(false)}>
         <Modal.Body className='text-center'>
            <img src={mataMask} className="avatar avatar-md-md rounded-circle shadow-sm " alt=""/>

            <div className="content mt-4">
                <h5 className="text-danger mb-4">Error!</h5>

                <p className="text-muted">Please Download MetaMask and create your profile and wallet in MetaMask. Please click and check the details,</p>

                <Link to="https://metamask.io/" className="btn btn-link primary text-primary fw-bold" target="_blank">MetaMask</Link>
            </div>
         </Modal.Body>
       </Modal>
        </header>
  )
};

export default Navbar;