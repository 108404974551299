import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { AsyncThunkConfig, RootState } from '../store';
import { CategoryListResponse, FetchParams } from '../../api/categories/types';

import {
    AddActionCases,
    GenericSliceState,
    HandleAsyncActionError,
} from '../../utils/helpers/reduxToolkitHelper';

export interface CategoriesState extends GenericSliceState {
    categoriesList: CategoryListResponse | null;

    isLoadingMenu: boolean,
    errorCodeMenu: string | null;
    errorMessageMenu: string;
    categoriesMenuList: CategoryListResponse | null;
}

export const initialState: CategoriesState = {
    isLoading: false,
    errorCode: null,
    errorMessage: '',
    categoriesList: null,

    isLoadingMenu: false,
    errorCodeMenu: null,
    errorMessageMenu: '',
    categoriesMenuList: null,
};

// ------------- //
// Async Actions //
// ------------- //

export const fetchCategories = createAsyncThunk<CategoryListResponse, FetchParams, AsyncThunkConfig>(
    'categories/fetchAll',
    async (params: FetchParams, thunkAPI) => {
        try {
            const result = await thunkAPI.extra.categories.fetchAll(params);
            return result;
        } catch (err: any) {
            return HandleAsyncActionError(err, thunkAPI);
        }
    },
);

export const fetchCategoriesMenu = createAsyncThunk<CategoryListResponse, FetchParams, AsyncThunkConfig>(
    'categories/fetchCategoriesMenu',
    async (params: FetchParams, thunkAPI) => {
        try {
            const result = await thunkAPI.extra.categories.fetchAll(params);
            return result;
        } catch (err: any) {
            return HandleAsyncActionError(err, thunkAPI);
        }
    },
);


// ----- //
// Slice //
// ----- //

export const categoriesSlice = createSlice({
    name: 'categories',
    initialState,
    reducers: {
        // -------------- //
        // Sync Reducers //
        // -------------- //

        clearCategories: (state, action: PayloadAction<void>) =>
            Object.assign(state, initialState),

        setCategoriesMenuFromCache(state, action: PayloadAction<CategoryListResponse>) {
            state.categoriesMenuList = action.payload;
        },
    },
    extraReducers: builder => {
        // -------------- //
        // Async Reducers //
        // -------------- //

        
        AddActionCases(
            builder,
            fetchCategories,
            (state, action) => {
                state.categoriesList = action.payload;
            },
            (state, action) => {
                state.categoriesList = null;
            },
            (state, action) => {},
        );

        AddActionCases(
            builder,
            fetchCategoriesMenu,
            (state, action) => {
                state.categoriesMenuList = action.payload;
            },
            (state, action) => {
                state.categoriesMenuList = null;
            },
            (state, action) => {},
            'Menu'
        );
    },
});

// ------------ //
// Sync Actions //
// ------------ //
export const { clearCategories, setCategoriesMenuFromCache } = categoriesSlice.actions;

export const categorySelector = (state: RootState) => state.categories;
export default categoriesSlice.reducer;
