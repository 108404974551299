import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { AsyncThunkConfig, RootState } from '../store';
import { CollectionListResponse } from '../../api/collections/types';
import { TrendingParams } from '../../api/stats/types';

import {
    AddActionCases,
    GenericSliceState,
    HandleAsyncActionError,
} from '../../utils/helpers/reduxToolkitHelper';

export interface StatsState extends GenericSliceState {
    collections:{
        trending: CollectionListResponse | null;
    };
    isLoadingCollectionsTrending: boolean;
    errorCodeCollectionsTrending: string | null;
    errorMessageCollectionsTrending: string;
    
    // general
    isLoading: boolean;
    errorCode: string | null;
    errorMessage: string;
}

export const initialState: StatsState = {
    collections:{
        trending: null
    },
    isLoadingCollectionsTrending: false,
    errorCodeCollectionsTrending: null,
    errorMessageCollectionsTrending: '',
    
    // general
    isLoading: false,
    errorCode: null,
    errorMessage: '',
};

// ------------- //
// Async Actions //
// ------------- //

export const fetchCollectionsTrending = createAsyncThunk<CollectionListResponse, TrendingParams, AsyncThunkConfig>(
    'stats/collections/trending',
    async (params: TrendingParams, thunkAPI) => {
        try {
            const result = await thunkAPI.extra.stats.collections.trending(params);
            return result;
        } catch (err: any) {
            return HandleAsyncActionError(err, thunkAPI);
        }
    },
);


// ----- //
// Slice //
// ----- //

export const statsSlice = createSlice({
    name: 'stats',
    initialState,
    reducers: {
        // -------------- //
        // Sync Reducers //
        // -------------- //

        clearStats: (state, action: PayloadAction<void>) =>
            Object.assign(state, initialState),
    },
    extraReducers: (builder) => {
        // -------------- //
        // Async Reducers //
        // -------------- //

        
        AddActionCases(
            builder,
            fetchCollectionsTrending,
            (state, action) => {
                state.collections.trending = action.payload;
            },
            (state, action) => {
                state.collections.trending = null;
            },
            (state, action) => {},
            'CollectionsTrending'
        );

    },
});

// ------------ //
// Sync Actions //
// ------------ //
export const { clearStats } = statsSlice.actions;

export const statsSelector = (state: RootState) => state.stats;
export default statsSlice.reducer;
