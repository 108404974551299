import { enqueueSnackbar } from 'notistack';
import React, { useEffect, useRef, useState } from 'react';
import {
  TwitterShareButton,
  XIcon,
  EmailShareButton,
  EmailIcon,
  TelegramShareButton,
  TelegramIcon,
  LinkedinShareButton,
  LinkedinIcon,
  WhatsappShareButton,
  WhatsappIcon
} from 'react-share';
import { useTranslation } from 'react-i18next';

export enum ButtonSize {
    SHORT = 'short',
    LARGE = 'large'
}

interface ShareButtonProps {
    size?: ButtonSize;
    title: string;
    url?: string;
}

const ShareButton: React.FC<ShareButtonProps> = ({ size, title, url }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const shareUrl = url || window.location.href;
  const dropdownRef = useRef<HTMLDivElement>(null);
  const buttonSize = size || ButtonSize.LARGE;

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(shareUrl).then(() => {
      enqueueSnackbar(t('common.buttons.copy.copied'));
    }).catch(() => {
      console.log("Failed to copy");
    });
  };

  const socialIcons = [
    { icon: <WhatsappIcon size={24} round />, label: t('common.buttons.copy.label'), onClick: handleCopyLink },
    { component: TwitterShareButton, icon: <XIcon size={24} round />, label: "Twitter" },
    { component: TelegramShareButton, icon: <TelegramIcon size={24} round />, label: "Telegram" },
    { component: EmailShareButton, icon: <EmailIcon size={24} round />, label: "Email" },
    { component: LinkedinShareButton, icon: <LinkedinIcon size={24} round />, label: "LinkedIn" },
    { component: WhatsappShareButton, icon: <WhatsappIcon size={24} round />, label: "WhatsApp" },
  ];

  return (
    <div className="position-relative" ref={dropdownRef}>
      <button onClick={toggleMenu} className={`btn me-2 ${buttonSize === ButtonSize.LARGE ? '' : 'p-0'}`} title={t('common.buttons.share.label')}>
        {buttonSize === ButtonSize.LARGE ? (
            <>
            <svg
            className="me-1"
            viewBox="0 0 25 24"
            fill="none"
            width="24"
            height="24"
            xmlns="http://www.w3.org/2000/svg"
            >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.5303 7.53032C10.2374 7.82321 9.76256 7.82321 9.46967 7.53032C9.17678 7.23743 9.17678 6.76255 9.46967 6.46966L12.4697 3.46966L13 2.93933L13.5303 3.46966L16.5303 6.46966C16.8232 6.76255 16.8232 7.23743 16.5303 7.53032C16.2374 7.82321 15.7626 7.82321 15.4697 7.53032L13.75 5.81065V15C13.75 15.4142 13.4142 15.75 13 15.75C12.5858 15.75 12.25 15.4142 12.25 15V5.81065L10.5303 7.53032ZM9.625 11.75C8.58947 11.75 7.75 12.5895 7.75 13.625V16C7.75 17.2426 8.75736 18.25 10 18.25H16C17.2426 18.25 18.25 17.2426 18.25 16V13.625C18.25 12.5895 17.4105 11.75 16.375 11.75C15.9608 11.75 15.625 11.4142 15.625 11C15.625 10.5858 15.9608 10.25 16.375 10.25C18.239 10.25 19.75 11.761 19.75 13.625V16C19.75 18.0711 18.0711 19.75 16 19.75H10C7.92893 19.75 6.25 18.0711 6.25 16V13.625C6.25 11.761 7.76104 10.25 9.625 10.25C10.0392 10.25 10.375 10.5858 10.375 11C10.375 11.4142 10.0392 11.75 9.625 11.75Z"
                fill="currentColor"
            />
            </svg>
            {t('common.buttons.share.label')}
        </>
        ):(
            <span className="uil uil-share-alt" style={{width:'24px'}}></span>
        )}
      </button>
      {isOpen && (
        <ul className="dropdown-menu show position-absolute px-2 py-3">
          {socialIcons.map((social, index) => (
              <li key={index} className={index !== socialIcons.length - 1 ? 'mb-2' : ''}>
              {social.component ? (
                <social.component url={shareUrl} title={title} className="btn btn-link d-flex align-items-center">
                  <span className="me-2">{social.icon}</span> {social.label}
                </social.component>
              ) : (
                <button className="react-share__ShareButton btn btn-link d-flex align-items-center p-0 mx-1" onClick={social.onClick}>
                  <span className="me-2 uil uil-link" style={{width:'24px'}}></span> {social.label}
                </button>
              )}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default ShareButton;