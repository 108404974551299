import React from 'react';
import { User, UserWallet } from 'api/users/types';
import cart from '../../../assets/images/svg/products-to-cart-or-basket.svg'
import { CrossmintNFTCollectionView } from '@crossmint/client-sdk-react-ui';
import { useTranslation } from 'react-i18next';

interface ProfileNftsProps {
    user: User;
}

const ProfileNfts   : React.FC<ProfileNftsProps> = ({ user }) => {
    const { t } = useTranslation();
    if (!user) return <div>Loading...</div>;
    
    return user && (
        <>
        <section className="section pt-0">
            <div className="container">
            { (user.wallets == null || (user.wallets.length === 0)) ? (
                    <div className="row justify-content-center">
                        <div className="col-lg-5 col-md-8 text-center">
                            <img src={cart} className="img-fluid" alt="nfts"/>
                            <div className="content mt-4">
                                <h5 className="mb-4">{t('profile.tabs.2.empty.title')}</h5>
                                <p className="text-muted" dangerouslySetInnerHTML={{ __html: t('profile.tabs.2.empty.subtitle') }}/>
                            </div>
                        </div>
                    </div>
            ) : (
                <div className="row">
                    <div className="col-lg-12 col-md-12 mt-0 mt-sm-0 pt-0 pt-sm-0">
                        {user.wallets?.map((item:UserWallet,index:number)=>{
                            const wallets = [
                                {
                                    chain: process.env.REACT_APP_CROSSMINT_ENVIRONMENT === 'staging' ? 'ethereum-sepolia' : 'ethereum',
                                    publicKey: item.wallet_address,
                                },
                            ];

                            //// @debug
                            // console.log('CrossmintNFTCollectionView wallets', wallets);

                            return(
                            <div  key={index} className="card nft-items nft-primary rounded-md shadow overflow-hidden mb-1 p-3">
                                <div style={{ height: "100vh" }}>
                                    <CrossmintNFTCollectionView wallets={wallets} />
                                </div>
                            </div>
                            )
                        })}
                    </div>
                </div>
            )}
            </div>
        </section>
        </>
    )
};

export default ProfileNfts;