import React, { createContext, useContext, useEffect } from 'react';
import Pusher from 'pusher-js';
import { useDispatch } from 'react-redux';
import { updateAllCollections } from '../store/collections/collections.slice';

const PusherContext = createContext<Pusher | null>(null);

export const PusherProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY || '', {
      cluster: process.env.REACT_APP_PUSHER_CLUSTER || '',
    });

    const collectionChannel = pusher.subscribe('collections');
    const handleUpdateAllCollections = (data: any) => {
      dispatch(updateAllCollections(data.data.collection));
    };

    // collectionChannel.bind('collection.updated', handleUpdateAllCollections);
    collectionChannel.bind('token.minted', handleUpdateAllCollections);

    // const tokenChannel = pusher.subscribe('tokens');
    // tokenChannel.bind('token.minted', (data: any) => {
    //   dispatch(updateToken(data));
    // });

    return () => {
      pusher.unsubscribe('collections');
      // pusher.unsubscribe('tokens');
    };
  }, [dispatch]);

  return (
    <PusherContext.Provider value={null}>
      {children}
    </PusherContext.Provider>
  );
};

export const usePusher = () => {
  const context = useContext(PusherContext);
  if (!context) {
    throw new Error('usePusher must be used within a PusherProvider');
  }
  return context;
};