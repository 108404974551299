import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom'

import { fetchInfluencers } from '../../store/influencers/influencers.slice';
import { AppDispatch, RootState } from '../../store/store';
import { Influencer, InfluencerListResponse } from '../../api/influencers/types';
import { FiltersYesNo } from '../../enums/filters';

import TinySlider from "tiny-slider-react";
import 'tiny-slider/dist/tiny-slider.css';
import {BestInfluencersProps} from 'interfaces/creator/BestInfluencersProps';
import { useTranslation } from 'react-i18next';
import LazyImage from 'components/lazy-image';

const BestInfluencers: React.FC<BestInfluencersProps> = (props) => {
    const dispatch = useDispatch<AppDispatch>();
    const { t } = useTranslation();
    const { isLoading = true, errorMessage } = useSelector((state: RootState) => state.influencers);
    const [influencersList = null, setInfluencers] = useState<InfluencerListResponse | null>(null);
    
    useEffect(() => {
        const fetchData = async () => {
            setInfluencers(null);
            const result = await dispatch(fetchInfluencers(props.params || {show_menu: FiltersYesNo.YES}));
            setInfluencers(result.payload);
        };
        fetchData();

        // dispatch(clearInfluencers());
        // dispatch(fetchInfluencers(props.params || {show_menu: FiltersYesNo.YES}));
    }, [dispatch, props]);

    // @todo verificar que hacer con este background ???
    function getBg() {
        const random = Math.floor(Math.random() * 12) + 1;
        return `/assets/images/work/${random}.jpg`;
    }

    const settings = {
        container: '.tiny-five-item-nav-arrow',
        controls: true,
        mouseDrag: true,
        loop: true,
        rewind: true,
        autoplay: true,
        autoplayButtonOutput: false,
        autoplayTimeout: 3000,
        navPosition: "bottom",
        controlsText: ['<i class="mdi mdi-chevron-left "></i>', '<i class="mdi mdi-chevron-right"></i>'],
        nav: false,
        speed: 400,
        gutter: 10,
        responsive: {
            992: {
                items: 5
            },

            767: {
                items: 3
            },

            320: {
                items: 1
            },
        },
    };

    const settingsLoading = {
        container: '.tiny-five-item-nav-arrow-loading',
        controls: true,
        mouseDrag: true,
        loop: true,
        rewind: true,
        autoplay: true,
        autoplayButtonOutput: false,
        autoplayTimeout: 3000,
        navPosition: "bottom",
        controlsText: ['<i class="mdi mdi-chevron-left "></i>', '<i class="mdi mdi-chevron-right"></i>'],
        nav: false,
        speed: 400,
        gutter: 10,
        responsive: {
            992: {
                items: 5
            },

            767: {
                items: 3
            },

            320: {
                items: 1
            },
        },
    };
  
    if (errorMessage) {
        return <div>Error: {errorMessage}</div>;
    }
    return (
        <div className="container">
            <div className="row align-items-end mb-4 pb-2">
                <div className="col-md-8">
                    <div className="section-title">
                        <h4 className="title mb-2">{t('creator.best_influencers.labels.title')}</h4>
                        <p className="text-muted mb-0">{t('creator.best_influencers.labels.subtitle')}</p>
                    </div>
                </div>

                {/* <div className="col-md-4">
                    <div className="text-end d-md-block d-none">
                        <Link to="/creators" className="btn btn-link primary text-dark">See More <i className="uil uil-arrow-right"></i></Link>
                    </div>
                </div> */}
            </div>
            <div className="row">
                <div className="col-12 mt-3">
                    {influencersList && (
                        <div className="tiny-five-item-nav-arrow">
                            <TinySlider settings={settings}>
                                {influencersList.data.map((item:Influencer)=>{
                                    return(
                                        <div className="tiny-slide" key={`${'bi-' + item.id}`}>
                                            <div className="card creators creators-two creator-primary rounded-md shadow overflow-hidden mx-2 my-3 bg-black">
                                                <div className="py-5" style={{backgroundImage:`url(${getBg()})`}}></div>
                                                <div className="position-relative mt-n5">
                                                    <LazyImage src={item.profile_photo_url} className="avatar avatar-md-md rounded-pill shadow-sm bg-light img-thumbnail mx-auto d-block" alt={item.username} height='76px'/>
                                                    
                                                    <div className="content text-center pt-2 p-4">
                                                        <Link to={`/profile/${item.username}`} className="text-dark h6 name d-block mb-0">{item.name}</Link>
                                                        <small className="text-muted">@{item.username}</small>

                                                        <div className="mt-3">
                                                            <Link to={`/profile/${item.username}`} className="btn btn-pills btn-primary">{t('common.buttons.view.label')}</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </TinySlider>
                        </div>
                    )}
                    {isLoading && (
                        <div className="tiny-five-item-nav-arrow-loading">
                            <TinySlider settings={settingsLoading}>
                            {Array.from({ length: 5 }).map((_, index) => (
                                <div className="tiny-slide" key={`${'bil-' + index}`}>
                                    <div className="card creators creators-two creator-primary rounded-md shadow overflow-hidden mx-2 my-3">
                                        <div className="py-5 bg-secondary placeholder-glow">
                                            <div className="placeholder w-100 h-100"></div>
                                        </div>
                                        <div className="position-relative mt-n5">
                                            <div className="avatar avatar-md-md rounded-pill shadow-sm bg-light img-thumbnail mx-auto d-block placeholder-glow">
                                                <div className="placeholder rounded-circle w-100 h-100"></div>
                                            </div>
                                            <div className="content text-center pt-2 p-4 placeholder-glow">
                                                <div className="placeholder w-50 mx-auto mb-2"></div>
                                                <br />
                                                <div className="placeholder w-25 mx-auto"></div>
                                                <div className="mt-3">
                                                    <div className="btn btn-pills btn-primary placeholder w-50 mx-auto"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            </TinySlider>
                        </div>
                    )}
                </div>
            </div>
            {/* <div className="row">
                <div className="col">
                    <div className="text-center d-md-none d-block">
                        <Link to="/creators" className="btn btn-link primary text-dark">See More <i className="uil uil-arrow-right"></i></Link>
                    </div>
                </div>
            </div> */}
        </div>
  )
};
export default BestInfluencers;