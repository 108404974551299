import React,{useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useParams, Navigate, useNavigate  } from 'react-router-dom';
import { RootState } from '../../store/store';
import { Category } from 'api/categories/types';

const CategoryDetail = () => {
    const { categoriesMenuList = null } = useSelector((state: RootState) => state.categories);
    const { slug } = useParams<{ slug: string }>();
    const [category, setCategory] = useState<Category | null>(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (categoriesMenuList && slug) {
            const foundCategory = categoriesMenuList.data.find(cat => cat.slug === slug);
            if (foundCategory) {
                setCategory(foundCategory || null);
            }else{
                navigate('/404', { replace: true });
            }
        }
    }, [categoriesMenuList, slug, navigate]);
    
    if(categoriesMenuList && category){
        try {
            const ThemeComponent = require(`../../components/category/display-${category?.display_theme}`).default;
            return <ThemeComponent category={category} />;
        } catch (error) {
            return <Navigate to="/404" />;
        }
    }
};

export default CategoryDetail;