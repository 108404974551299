import axiosInstance from '../axiosInstances';
import { SocialPlatformListResponse } from './types';

const socialPlatforms = {
  fetchAll: async (): Promise<SocialPlatformListResponse> => {
    const response = await axiosInstance.get(`/social_platforms`);
    return response.data as SocialPlatformListResponse;
  },
};

export default socialPlatforms;