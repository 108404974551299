import React from 'react'
import { useAppContext } from 'context/AppContextProvider';

const AccountNotification = () => {
    const { authUser } = useAppContext();
    
    return authUser && (
        <div>
        <h1>Account Notifications</h1>
        <p>Name: {authUser?.name}</p>
        <p>username: {authUser?.username}</p>
        </div>
    )
};

export default AccountNotification;