import React from 'react';
import { Link } from 'react-router-dom'

import { Category } from '../../api/categories/types';
// import { useTranslation } from 'react-i18next';

interface Props {
    category: Category;
    className?: string;
}

const CategoryBadge: React.FC<Props> = ({category, className}) =>{
    // const { t } = useTranslation();
    return (
    <Link to={`/category/${category.slug}`} className={className || "badge badge-link bg-primary text-white me-1"}>
        {category.name}
    </Link>
  )
}

export default CategoryBadge;