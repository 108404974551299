import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom'
import moment from 'moment';

import { fetchComingSoonCollections } from '../../store/collections/collections.slice';
import { AppDispatch, RootState } from '../../store/store';
import { Collection, CollectionMintStage } from '../../api/collections/types';
import { OrderBy } from '../../api/collections/enums';
import { FiltersYesNo } from '../../enums/filters';
import LazyImage from '../lazy-image';
import { Category } from 'api/categories/types';
import CategoryBadge from './category-badge';
import { useTranslation } from 'react-i18next';

interface Props {
    filter?: boolean;
}

const ComingSoonCollection: React.FC<Props> = ({filter = true}) =>{
    const dispatch = useDispatch<AppDispatch>();
    const { t } = useTranslation();
    const { comingSoonCollections, isLoadingComingSoon, errorMessageComingSoon } = useSelector((state: RootState) => state.collections);
    
    useEffect(() => {
        dispatch(fetchComingSoonCollections({coming_soon: FiltersYesNo.YES, order_by: OrderBy.RANDOM, collection_fields: 'owner,categories,stages'}));
    }, [dispatch]);

    const getUpcomingStage = (stages: CollectionMintStage[]): CollectionMintStage | null => {
        const now = new Date();
        const upcomingStage = stages
        .filter(stage => new Date(stage.start_at) > now)
        .sort((a, b) => new Date(a.start_at).getTime() - new Date(b.start_at).getTime())[0];

        return upcomingStage || null;
    };

    if (errorMessageComingSoon) {
        return <div>Error: {errorMessageComingSoon}</div>;
    }


    return comingSoonCollections && comingSoonCollections.data.length > 0 && (
        <div className="container mt-100 mt-60">
            
            <div className="row justify-content-center">
                <div className="col">
                    <div className="section-title text-center mb-5 pb-3">
                        <h4 className="title mb-4">{t('collection.coming_soon.labels.title')}</h4>
                        <p className="text-muted para-desc mb-0 mx-auto">{t('collection.coming_soon.labels.subtitle')}</p>
                    </div>
                </div>
            </div>

            <div className="row g-4">
                {comingSoonCollections && comingSoonCollections.data.slice(0,3).map((item:Collection,index)=>{
                    const upcomingStage = getUpcomingStage(item.stages || []);
                    return(
                        <div className="col-lg-4 col-md-6" key={`cs-${item.id}`}>
                            <div className="card blog blog-primary shadow rounded-md overflow-hidden">
                                <div className="position-relative">
                                    <Link to={`/collection/${item.slug}`}><LazyImage src={item.logo_permalink} className="img-fluid rounded-md" alt={item.slug} height='356px'/></Link>
                                </div>
                                <div className="card-body position-relative p-4">
                                    {item.categories && item.categories.length > 0 && ( 
                                        <div className="tag-container">
                                            {item.categories.map((category: Category) => (
                                                <CategoryBadge key={`cs-c-${category.id}`} category={category} className="badge tag gradient rounded-md fw-bold text-white me-2" />
                                            ))}
                                        </div>
                                    )}
                                    {upcomingStage && (
                                        <ul className="list-unstyled mt-2">
                                            <li className="list-inline-item text-muted small me-3"><i className="uil uil-calendar-alt text-dark h6 me-1"></i>{moment(upcomingStage.start_at).format('L LT')}</li>
                                        </ul>
                                    )}
                                    <Link to={`/collection/${item.slug}`} className="text-dark title h5 mt-3">{item.name}</Link>
                                    
                                    <div className="mt-3 d-flex justify-content-between align-items-center">
                                        <Link to={`/collection/${item.slug}`} className="btn btn-link text-muted">{t('common.buttons.read_more.label')} <i className="uil uil-arrow-right fs-5"></i></Link>
                                        <span className="text-muted fs-6">by <Link to={`/profile/${item.owner.username}`} className="link">@{item.owner.username}</Link></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                )}

                {isLoadingComingSoon && Array.from({ length: 3 }).map((_, index) => (
                    <div className="col-lg-4 col-md-6" key={`cs-${index}`}>
                        <div className="card blog blog-primary shadow rounded-md overflow-hidden">
                            <div className="position-relative placeholder-glow">
                                <div className="placeholder w-100" style={{ height: '356px' }}></div>
                            </div>
                            <div className="card-body position-relative p-4">
                                <div className="placeholder-glow">
                                    <div className="placeholder w-75 mb-2"></div>
                                    <div className="placeholder w-50 mb-2"></div>
                                </div>
                                <div className="mt-3 d-flex justify-content-between align-items-center placeholder-glow">
                                    <div className="placeholder w-25"></div>
                                    <div className="placeholder w-50"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default ComingSoonCollection;