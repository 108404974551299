import axiosInstance from '../axiosInstances';
import { User } from 'api/users/types';
import { AuthUserParams } from './types';

const auth = {
  login: async (params?: AuthUserParams): Promise<User> => {

    // @debug
    // console.log('API auth.login params', params);

    const response = await axiosInstance.post(`/auth/login`, params);

    // @debug
    // console.log('API auth.login response', response.data.data);

    return response.data.data as User;
  },
};

export default auth;