import axiosInstance from '../axiosInstances';
import { CollectionListResponse, Collection, FetchParams, CollectionTraitsCategoriesListResponse } from './types';

const collections = {
  getByIdentifier: async (identifier:string, params?: FetchParams): Promise<Collection> => {
    const response = await axiosInstance.get(`/collections/${identifier}`, {
      params,
    });
    return response.data.data as Collection;
  },
  fetchAll: async (params?: FetchParams): Promise<CollectionListResponse> => {
    const response = await axiosInstance.get(`/collections`, {
      params,
    });
    return response.data as CollectionListResponse;
  },
  fetchCursor: async (cursor:string): Promise<CollectionListResponse> => {
    const response = await axiosInstance.get(`${cursor}`);
    return response.data as CollectionListResponse;
  },
  fetchTraits: async (identifier:string): Promise<CollectionTraitsCategoriesListResponse> => {
    const response = await axiosInstance.get(`/collections/${identifier}/traits`);
    return response.data as CollectionTraitsCategoriesListResponse;
  },
};

export default collections;