import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store/store';
import { fetchAll, fetchCursor } from '../../../store/collections/collections.slice';
import { OrderBy } from '../../../api/collections/enums';
import { FetchParams, Collection } from '../../../api/collections/types';
import LazyImage from '../../lazy-image';
import { getNextStage } from '../../../utils/helpers/collectionHelper';
import { formatSalePrice } from '../../../utils/helpers/formatHelper';
import { User } from 'api/users/types';
import cart from '../../../assets/images/svg/products-to-cart-or-basket.svg'
import { UserType } from 'api/users/enums';
import { useTranslation } from 'react-i18next';

interface ProfileCollectionsProps {
    user: User;
}

const ProfileCollections   : React.FC<ProfileCollectionsProps> = ({ user }) => {
    const dispatch = useDispatch<AppDispatch>();
    const { t } = useTranslation();
    const { collections, isLoading = true, orderBy } = useSelector((state: RootState) => state.collections);
    const [selectedCategories] = useState<string[]>([]);
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    
    useEffect(() => {
        if(user){
            const params: FetchParams = {
                per_page: 6,
                order_by: orderBy as OrderBy,
                collection_fields: 'owner,categories,stages',
                category_slug: selectedCategories.join(','),
                owner: user.username,
            };
            dispatch(fetchAll(params));
        }
    }, [dispatch, user, orderBy, selectedCategories]);

    const handleLoadMore = () => {
        if (collections?.links?.next) {
            dispatch(fetchCursor({cursor: collections.links.next}));
        }
    };

    if (!user) return <div>Loading...</div>;
    
    return user && (
        <>
        <section className="section pt-0">
            <div className="container">
            { !isLoading && (collections == null || (collections?.data.length === 0)) ? (
                    <div className="row justify-content-center">
                        <div className="col-lg-5 col-md-8 text-center">
                            <img src={cart} className="img-fluid" alt="collections"/>

                            {user.type === UserType.Influencer ? (
                                <div className="content mt-4">
                                    <h5 className="mb-4">{t('profile.tabs.1.influencer.empty.title')}</h5>
                                    <p className="text-muted">{t('profile.tabs.1.influencer.empty.subtitle')}</p>
                                </div>
                            ) : (
                                <div className="content mt-4">
                                    <h5 className="mb-4">{t('profile.tabs.1.empty.title')}</h5>
                                    <p className="text-muted">{t('profile.tabs.1.empty.subtitle')}</p>
                                </div>
                            )}
                        </div>
                    </div>
            ) : (
                <div className="row">
                    {/* <div className="col-lg-3 col-md-6">
                        <ProfileCollectionsFilter user={user} collections={collections?.data} onCategoryChange={setSelectedCategories} />
                    </div> */}

                    <div className="col-lg-12 col-md-12 mt-0 mt-sm-0 pt-0 pt-sm-0">
                        <div className="row row-cols-xl-4 row-cols-lg-3 row-cols-1">
                            {collections?.data.map((item:Collection,index:number)=>{
                                const stage = getNextStage(item?.stages || [], userTimeZone);
                                return(
                                <div className="col mt-4 pt-2" key={index}>
                                    <div className="card nft-items nft-primary rounded-md shadow overflow-hidden mb-1 p-3">
                                        
                                        <div className="nft-image rounded-md position-relative overflow-hidden">
                                            <Link to={`/collection/${item.slug}`}><LazyImage src={item.logo_permalink} className="img-fluid" alt={item.slug}/></Link>
                                        </div>

                                        <div className="card-body content position-relative p-0 mt-3">
                                            <Link to={`/collection/${item.slug}`} className="title text-dark h6">{item.name}</Link>

                                            {stage && (
                                            <div className="d-flex justify-content-between mt-2">
                                                <small className="rate fw-bold">{formatSalePrice(stage.sale_price)}</small>
                                                {/* <small className="text-dark fw-bold">1 out of 10</small> */}
                                            </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                )
                            })}

                            {isLoading && Array.from({ length: 4 }).map((_, index) => (
                            <div className="col mt-4 pt-2" key={index}>
                                <div className="card nft-items nft-primary rounded-md shadow overflow-hidden mb-1 p-3">
                                    <div className="nft-image rounded-md position-relative overflow-hidden">
                                        <div className="placeholder-glow">
                                            <div className="placeholder" style={{ height: '229px', width: '100%' }}></div>
                                        </div>
                                    </div>
                                    <div className="card-body content position-relative p-0 mt-3">
                                        <div className="placeholder-glow">
                                            <div className="placeholder w-75 mb-2"></div>
                                            <div className="d-flex justify-content-between mt-2">
                                                <div className="placeholder w-25"></div>
                                                <div className="placeholder w-25"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            ))}
                        </div>

                        {collections?.links && collections.links.next && (
                            <div className="row justify-content-center mt-4">
                                <div className="col">
                                    <div className="text-center">
                                        <button onClick={handleLoadMore} className="btn btn-primary rounded-md">
                                            {isLoading ?
                                                <>
                                                <i className="uil uil-process mdi-spin me-1"></i> {t('common.buttons.load_more.loading')}
                                                </>
                                                :
                                                <>
                                                {t('common.buttons.load_more.label')}
                                                </>
                                            }
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}
            </div>
        </section>
        </>
    )
};

export default ProfileCollections;